import { useCallback } from 'react';

import { UpdateFormInput } from '../types';

export const useFormState = () => {
    const updateForm = useCallback(
        (
            setFormState: React.Dispatch<React.SetStateAction<any>>,
            { formSection, subSection, value, id }: UpdateFormInput
        ) => {
            setFormState((prevState: any) => {
                if (subSection && formSection) {
                    return {
                        ...prevState,
                        [formSection]: {
                            ...prevState[formSection],
                            [subSection]: {
                                ...prevState[formSection][subSection],
                                [id]: value
                            }
                        }
                    };
                }

                if (formSection) {
                    return {
                        ...prevState,
                        [formSection]: {
                            ...prevState[formSection],
                            [id]: value
                        }
                    };
                }

                return { ...prevState, [id]: value };
            });
        },
        []
    );

    return { updateForm };
};
