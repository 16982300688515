import { Text } from '@usercentrics/cookiebot-ui-react';
import { Trans } from 'react-i18next';

export const ReportLegalNote = () => (
    <Text color="#666">
        <Trans
            i18nKey="reportPage.legalNotice"
            components={{
                title: (
                    <Text fontWeight="700" as="span">
                        Legal Note:
                    </Text>
                )
            }}
        />
    </Text>
);
