import { Button } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAmplitude } from '../../hooks';

export const ScanStatusViewScanBtn = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { sendEvent } = useAmplitude();

    const handleOnClick = () => {
        sendEvent('Navigate using scan report button');
        navigate('/report');
    };

    return (
        <Button onClick={handleOnClick}>
            {t('dashboard.scanOverview.scanStatusDone.viewReport')}
        </Button>
    );
};
