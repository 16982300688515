import i18n from 'i18next';

export const getTranslation = (
    key: string,
    fallbackKey: string,
    options: { [key: string]: string }
) => {
    if (i18n.exists(key, options)) {
        return i18n.t(key, options);
    }

    return i18n.t(fallbackKey, options);
};
