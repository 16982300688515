import { Box } from '@usercentrics/cookiebot-ui-react';
import { ReactNode } from 'react';

import styles from './Main.module.css';

type MainProps = {
    children: ReactNode;
};

export const Main = ({ children }: MainProps) => (
    <Box className={styles.root} px={8}>
        {children}
    </Box>
);
