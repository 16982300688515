import { Divider } from '@chakra-ui/react';
import { Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { LanguageSettingsFormState } from '../../pages/Content/types/Content.types';
import { FormUpdate } from '../../types';
import { AddedLanguages } from './AddedLanguages/AddedLanguages';
import { AddLanguage } from './AddLanguage/AddLanguage';

type Props = {
    formState: LanguageSettingsFormState;
    editableLanguages: string[];
    supportedLanguages: { label: string; value: string }[];
} & FormUpdate;

export const LanguageSettingsTab = ({
    formState,
    updateForm,
    editableLanguages,
    supportedLanguages
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.languageSettings'
    });

    return (
        <Box>
            <Stack spacing="40px">
                <Box
                    backgroundColor="#F2F2F2"
                    borderRadius="16px"
                    px="40px"
                    py="40px"
                >
                    <Stack spacing="24px">
                        <Box>
                            <Text fontSize="28px" fontWeight="700" mb="16px">
                                {t('title')}
                            </Text>
                            <Text fontSize="15px" color="#141414" mb="32px">
                                {t('desc')}
                            </Text>
                            <Text fontSize="13px" fontWeight={600}>
                                {t('note')}
                            </Text>
                        </Box>
                        <Divider />
                        <AddLanguage
                            addedLanguages={formState.addedLanguages}
                            updateForm={updateForm}
                            supportedLanguages={supportedLanguages}
                        />
                    </Stack>
                </Box>
                <AddedLanguages
                    addedLanguages={formState.addedLanguages}
                    updateForm={updateForm}
                    editableLanguages={editableLanguages}
                />
            </Stack>
        </Box>
    );
};
