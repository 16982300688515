import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAmplitude } from './useAmplitude';

export const usePageViews = () => {
    const { pathname } = useLocation();
    const { sendEvent } = useAmplitude();

    useEffect(() => {
        sendEvent('Page View', { pathname });
    }, [pathname, sendEvent]);
};
