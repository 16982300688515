import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Text
} from '@usercentrics/cookiebot-ui-react';

type Props = {
    title: string;
    children: React.ReactNode;
    expandedByDefault?: boolean;
};

export const ColorsAccordionWrapper = ({
    title,
    children,
    expandedByDefault = false
}: Props) => (
    <Accordion
        defaultIndex={expandedByDefault ? [0] : undefined}
        allowToggle
        reduceMotion
    >
        <AccordionItem>
            <AccordionButton
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                textAlign="left"
            >
                <Text fontSize="18px" color="#141414">
                    {title}
                </Text>
                <AccordionIcon color="#141414" />
            </AccordionButton>
            <AccordionPanel borderTopWidth={1}>{children}</AccordionPanel>
        </AccordionItem>
    </Accordion>
);
