import {
    Box,
    ColorPicker,
    Grid,
    Input,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TogglesFormState } from '../../../pages/Appearance/types/consentBanner.type';
import { FormUpdate } from '../../../types';

type Props = FormUpdate & {
    togglesFormState: TogglesFormState;
};

const formSection = 'toggleColors';

export const ConsentBannerToggleColors = ({
    updateForm,
    togglesFormState
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner.toggleColors'
    });
    const colorsKeys = useMemo(() => {
        if (togglesFormState) {
            return Object.keys(togglesFormState);
        }

        return [];
    }, [togglesFormState]);

    return (
        <Grid templateRows="1fr 1fr 1fr" templateColumns="1fr 1fr" gap="20px">
            {colorsKeys.map((colorKey) => (
                <Box key={colorKey}>
                    <Text fontSize={14} fontWeight={600}>
                        {t(colorKey)}
                    </Text>
                    <Box display="flex" gap="8px">
                        <ColorPicker
                            value={togglesFormState[colorKey] as string}
                            onChange={(event: any) =>
                                updateForm({
                                    formSection,
                                    id: colorKey,
                                    value: event.target.value
                                })
                            }
                        />
                        <Input
                            value={togglesFormState[colorKey] as string}
                            onChange={(event) => {
                                updateForm({
                                    formSection,
                                    id: colorKey,
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                </Box>
            ))}
        </Grid>
    );
};
