import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de/translations.json';
import en from './locales/en/translations.json';
import es from './locales/es/translations.json';
import fr from './locales/fr/translations.json';
import it from './locales/it/translations.json';
import pt from './locales/pt/translations.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: en
    },
    de: {
        translation: de
    },
    es: {
        translation: es
    },
    fr: {
        translation: fr
    },
    it: {
        translation: it
    },
    pt: {
        translation: pt
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
