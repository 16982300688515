import { AdminPasswordModal } from '@components/AdminPasswordModal';
import { Footer } from '@components/Footer';
import { FullPageLoader } from '@components/FullPageLoader/FullPageLoader';
import { Header } from '@components/Header';
import { LeaveReviewModal } from '@components/LeaveReviewModal';
import { Logs } from '@components/Logs';
import { Main } from '@components/Main';
import { UpgradePremiumModal } from '@components/UpgradePremiumModal';
import { RouterProvider } from 'react-router-dom';

import {
    useAppConfig,
    useAuth,
    useClientSettings,
    usePremium,
    useReport
} from './hooks';
import { useScreenResolution } from './hooks/useScreenResolution';
import { router } from './routes';

export const App = () => {
    useAuth();
    useAppConfig();
    usePremium();
    useClientSettings();
    useReport();
    useScreenResolution();

    return (
        <>
            <FullPageLoader />
            <UpgradePremiumModal />
            <LeaveReviewModal />
            <AdminPasswordModal />
            <Header navigate={router.navigate} />
            <Main>
                <RouterProvider router={router} />
            </Main>
            <Footer />
            {`${import.meta.env.VITE_ENV}` !== 'local' && <Logs />}
        </>
    );
};
