import { useEffect } from 'react';

import { LogRequestLevel } from '../api/generated-types';
import { useSendLogAPI } from '../api/hooks';
import {
    useAppConfigStore,
    useAuthStore,
    useClientSettingsStore,
    usePremiumStore,
    useReportStore
} from '../stores';

export const useLogs = (sendToBackend: boolean = true) => {
    const { mutate: sendLog } = useSendLogAPI({});
    const { domain, siteId, appInstanceId, appId } = useAuthStore();
    const { appLoading } = useAppConfigStore();
    const { clientSettingsId, clientSettingsLoading } =
        useClientSettingsStore();
    const { isPremium, isPremiumLoading } = usePremiumStore();
    const {
        status,
        complianceStatus,
        hasConflict,
        isLowRisk,
        message,
        lastScanDate,
        nextScanDate
    } = useReportStore();

    useEffect(() => {
        if (!sendToBackend) {
            console.log('App started');
        }

        if (sendToBackend) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'App started',
                parameters: []
            });
        }
    }, [sendLog, sendToBackend]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log('App initialized for', appInstanceId);
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'App initialized for {appInstanceId}',
                parameters: [appInstanceId]
            });
        }
    }, [sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(`App loading is ${appLoading} for ${appInstanceId}`);
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'App loading is {appLoading} for {appInstanceId}',
                parameters: [`${appLoading}`, appInstanceId]
            });
        }
    }, [appInstanceId, appLoading, sendLog, sendToBackend]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(`App instance ID is ${appInstanceId}`);
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'App instance ID is {appInstanceId}',
                parameters: [appInstanceId]
            });
        }
    }, [appInstanceId, sendLog, sendToBackend]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(`Domain is ${domain} for ${appInstanceId}`);
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'Domain is {domain} for {appInstanceId}',
                parameters: [domain as string, appInstanceId]
            });
        }
    }, [domain, sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(`App ID is ${appId} for ${appInstanceId}`);
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'App ID is {appId} for {appInstanceId}',
                parameters: [appId, appInstanceId]
            });
        }
    }, [appId, sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(`Site ID is ${siteId} for ${appInstanceId}`);
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'Site ID is {siteId} for {appInstanceId}',
                parameters: [siteId, appInstanceId]
            });
        }
    }, [siteId, sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(
                `Client settings ID is ${clientSettingsId} for ${appInstanceId}`
            );
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template:
                    'Client settings ID is {clientSettingsId} for {appInstanceId}',
                parameters: [`${clientSettingsId}`, appInstanceId]
            });
        }
    }, [clientSettingsId, sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(
                `Client settings loading is ${clientSettingsLoading} for ${appInstanceId}`
            );
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template:
                    'Client settings loading is {clientSettingsLoading} for {appInstanceId}',
                parameters: [`${clientSettingsLoading}`, appInstanceId]
            });
        }
    }, [clientSettingsLoading, sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(`Is premium is ${isPremium} for ${appInstanceId}`);
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template: 'Is premium is {isPremium} for {appInstanceId}',
                parameters: [`${isPremium}`, appInstanceId]
            });
        }
    }, [isPremium, sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(
                `Is premium loading is ${isPremiumLoading} for ${appInstanceId}`
            );
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template:
                    'Is premium loading is {isPremiumLoading} for {appInstanceId}',
                parameters: [`${isPremiumLoading}`, appInstanceId]
            });
        }
    }, [isPremiumLoading, sendLog, sendToBackend, appInstanceId]);

    useEffect(() => {
        if (!sendToBackend && appInstanceId) {
            console.log(
                `Report details - Status is ${status}, Compliance status is ${complianceStatus}, Has conflict is ${hasConflict}, Is low risk is ${isLowRisk}, Message is ${message}, Last scan date is ${lastScanDate}, Next scan date is ${nextScanDate} for ${appInstanceId}`
            );
        }

        if (sendToBackend && appInstanceId) {
            sendLog({
                level: LogRequestLevel.Info,
                template:
                    'Report details - Status is {status}, Compliance status is {complianceStatus}, Has conflict is {hasConflict}, Is low risk is {isLowRisk}, Message is {message}, Last scan date is {lastScanDate}, Next scan date is {nextScanDate} for {appInstanceId}',
                parameters: [
                    status,
                    complianceStatus,
                    `${hasConflict}`,
                    `${isLowRisk}`,
                    message,
                    lastScanDate,
                    nextScanDate,
                    appInstanceId
                ]
            });
        }
    }, [
        status,
        sendLog,
        complianceStatus,
        hasConflict,
        isLowRisk,
        message,
        lastScanDate,
        nextScanDate,
        sendToBackend,
        appInstanceId
    ]);
};
