import { Divider } from '@chakra-ui/react';
import {
    Box,
    Flex,
    Icon,
    MdMoreHoriz,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { Trans, useTranslation } from 'react-i18next';

export const ActionRequiredBanner = () => {
    const { t } = useTranslation();

    return (
        <Box borderRadius="2xl" bg="#FDECCF" p={6}>
            <Stack spacing="16px">
                <Flex alignItems="center" justifyContent="space-between">
                    <Flex>
                        <Text fontSize="17px" fontWeight={600}>
                            {t('common.actionRequired')}
                        </Text>
                    </Flex>
                </Flex>
                <Text>{t('reportPage.actionRequiredBanner1p')}</Text>
                <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                    <Text alignItems="center" display="flex" gap={1}>
                        <Trans
                            i18nKey="reportPage.actionRequiredBannerRemoveApps"
                            defaults="To remove apps go to the <bold>Wix dashboard</bold> for your site. Choose Apps <gt /> Installed Apps <gt /> <icon /> <gt /> Delete"
                            components={{
                                bold: <strong />,
                                gt: <>&gt;</>,
                                icon: (
                                    <Icon
                                        as={MdMoreHoriz}
                                        borderWidth={1}
                                        borderColor="black"
                                        borderRadius="full"
                                        stroke="grey.900"
                                        mx={1}
                                    />
                                )
                            }}
                        />
                    </Text>
                </Box>
                <Divider />
                <Text size="xs" variant="subtle" fontWeight={500}>
                    {t('reportPage.actionRequiredBannerNote')}
                </Text>
            </Stack>
        </Box>
    );
};
