import { AppearanceConfiguration } from '../types/appearanceConfiguration.type';
import { ConsentBannerFormState } from '../types/consentBanner.type';
import { PrivacyTriggerFormState } from '../types/privacyTrigger.type';
import {
    consentBannerInitialFormState,
    privacyTriggerInitialFormState
} from './appearanceFormStateUtils';

const convertOpacity = (opacity: number | null) => {
    if (!opacity) {
        return null;
    }

    return (opacity * 100).toString();
};

const intToFloatingPoint = (int: number) => int / 100;

const getGeneralColors = (
    data: AppearanceConfiguration,
    consentBannerFormState: ConsentBannerFormState
) => ({
    backgroundColor: data.customization.color.layerBackground
        ? `#${data.customization.color.layerBackground}`
        : consentBannerFormState.generalColors.backgroundColor,
    linkColor: data.customization.color.linkFont
        ? `#${data.customization.color.linkFont}`
        : consentBannerFormState.generalColors.linkColor,
    tabColor: data.customization.color.secondLayerTab
        ? `#${data.customization.color.secondLayerTab}`
        : consentBannerFormState.generalColors.tabColor,
    textColor: data.customization.color.text
        ? `#${data.customization.color.text}`
        : consentBannerFormState.generalColors.textColor,
    accentColor: data.customization.color.tabsBorderColor
        ? `#${data.customization.color.tabsBorderColor}`
        : consentBannerFormState.generalColors.accentColor
});

const getButtonColors = (
    data: AppearanceConfiguration,
    consentBannerFormState: ConsentBannerFormState
) => ({
    acceptButtonBackground: data.customization.color.acceptBtnBackground
        ? `#${data.customization.color.acceptBtnBackground}`
        : consentBannerFormState.buttonColors.acceptButtonBackground,
    acceptButtonText: data.customization.color.acceptBtnText
        ? `#${data.customization.color.acceptBtnText}`
        : consentBannerFormState.buttonColors.acceptButtonText,
    denyButtonBackground: data.customization.color.denyBtnBackground
        ? `#${data.customization.color.denyBtnBackground}`
        : consentBannerFormState.buttonColors.denyButtonBackground,
    denyButtonText: data.customization.color.denyBtnText
        ? `#${data.customization.color.denyBtnText}`
        : consentBannerFormState.buttonColors.denyButtonText,
    saveButtonBackground: data.customization.color.saveBtnBackground
        ? `#${data.customization.color.saveBtnBackground}`
        : consentBannerFormState.buttonColors.saveButtonBackground,
    saveButtonText: data.customization.color.saveBtnText
        ? `#${data.customization.color.saveBtnText}`
        : consentBannerFormState.buttonColors.saveButtonText,
    moreBtnBackground: data.customization.color.moreBtnBackground
        ? `#${data.customization.color.moreBtnBackground}`
        : consentBannerFormState.buttonColors.moreBtnBackground,
    moreBtnText: data.customization.color.moreBtnText
        ? `#${data.customization.color.moreBtnText}`
        : consentBannerFormState.buttonColors.moreBtnText
});

const getToggleColors = (
    data: AppearanceConfiguration,
    consentBannerFormState: ConsentBannerFormState
) => ({
    activeToggleBackground: data.customization.color.toggleActiveBackground
        ? `#${data.customization.color.toggleActiveBackground}`
        : consentBannerFormState.toggleColors.activeToggleBackground,
    activeToggleIcon: data.customization.color.toggleActiveIcon
        ? `#${data.customization.color.toggleActiveIcon}`
        : consentBannerFormState.toggleColors.activeToggleIcon,
    disabledToggleBackground: data.customization.color.toggleDisabledBackground
        ? `#${data.customization.color.toggleDisabledBackground}`
        : consentBannerFormState.toggleColors.disabledToggleBackground,
    disabledToggleIcon: data.customization.color.toggleDisabledIcon
        ? `#${data.customization.color.toggleDisabledIcon}`
        : consentBannerFormState.toggleColors.disabledToggleIcon,
    inactiveToggleBackground: data.customization.color.toggleInactiveBackground
        ? `#${data.customization.color.toggleInactiveBackground}`
        : consentBannerFormState.toggleColors.inactiveToggleBackground,
    inactiveToggleIcon: data.customization.color.toggleInactiveIcon
        ? `#${data.customization.color.toggleInactiveIcon}`
        : consentBannerFormState.toggleColors.inactiveToggleIcon
});

const getAdvancedSettings = (
    data: AppearanceConfiguration,
    consentBannerFormState: ConsentBannerFormState
) => ({
    useBackgroundShadow: data.customization.useBackgroundShadow,
    backgroundOverlay: data.firstLayer.isOverlayEnabled,
    overlayOpacity: convertOpacity(data.customization.overlayOpacity),
    overlayColor: data.customization.color.overlay
        ? `#${data.customization.color.overlay}`
        : consentBannerFormState.advancedSettings.overlayColor
});

export const mapResponseToConsentBannerFormState = (
    data: AppearanceConfiguration
) => {
    const consentBannerFormState: ConsentBannerFormState = {
        layoutType: data.firstLayer.variant,
        theme: data.theme ? data.theme : 'custom',
        generalColors: getGeneralColors(data, consentBannerInitialFormState),
        buttonColors: getButtonColors(data, consentBannerInitialFormState),
        toggleColors: getToggleColors(data, consentBannerInitialFormState),
        advancedSettings: getAdvancedSettings(
            data,
            consentBannerInitialFormState
        )
    };

    return consentBannerFormState;
};

export const mapResponseToPrivacyTriggerFormState = (
    data: AppearanceConfiguration
) => {
    const privacyTriggerFormState: PrivacyTriggerFormState = {
        placement: data.buttonDisplayLocation,
        icon: data.buttonPrivacyOpenIconUrl,
        iconColors: {
            background: data.customization.color.privacyButtonBackground
                ? `#${data.customization.color.privacyButtonBackground}`
                : privacyTriggerInitialFormState.iconColors.background,
            icon: data.customization.color.privacyButtonIcon
                ? `#${data.customization.color.privacyButtonIcon}`
                : privacyTriggerInitialFormState.iconColors.icon
        }
    };

    return privacyTriggerFormState;
};

export const mapFormStateToPayload = ({
    consentBannerFormState,
    privacyTriggerFormState
}: {
    consentBannerFormState: ConsentBannerFormState;
    privacyTriggerFormState: PrivacyTriggerFormState;
}) => {
    const payload: AppearanceConfiguration = {
        theme:
            consentBannerFormState.theme !== 'custom'
                ? consentBannerFormState.theme
                : null,
        firstLayer: {
            variant: consentBannerFormState.layoutType,
            hideButtonDeny: null,
            hideLanguageSwitch: null,
            isCategoryTogglesEnabled: null,
            secondLayerTrigger: null,
            logoPosition: null,
            isOverlayEnabled:
                consentBannerFormState.advancedSettings.backgroundOverlay
        },
        secondLayer: {
            variant: 'center',
            hideButtonDeny: null,
            hideLanguageSwitch: null,
            isOverlayEnabled:
                consentBannerFormState.advancedSettings.backgroundOverlay
        },
        buttonPrivacyOpenIconUrl: privacyTriggerFormState.icon,
        buttonDisplayLocation: privacyTriggerFormState.placement,
        privacyButtonIsVisible: true,
        customization: {
            borderRadiusButton: null,
            borderRadiusLayer: null,
            color: {
                acceptBtnBackground:
                    consentBannerFormState.buttonColors.acceptButtonBackground,
                acceptBtnText:
                    consentBannerFormState.buttonColors.acceptButtonText,
                denyBtnBackground:
                    consentBannerFormState.buttonColors.denyButtonBackground,
                denyBtnText: consentBannerFormState.buttonColors.denyButtonText,
                layerBackground:
                    consentBannerFormState.generalColors.backgroundColor,
                overlay: consentBannerFormState.advancedSettings
                    .backgroundOverlay
                    ? consentBannerFormState.advancedSettings.overlayColor
                    : null,
                privacyButtonBackground:
                    privacyTriggerFormState.iconColors.background,
                privacyButtonIcon: privacyTriggerFormState.iconColors.icon,
                linkFont: consentBannerFormState.generalColors.linkColor,
                saveBtnBackground:
                    consentBannerFormState.buttonColors.saveButtonBackground,
                saveBtnText: consentBannerFormState.buttonColors.saveButtonText,
                moreBtnBackground:
                    consentBannerFormState.buttonColors.moreBtnBackground,
                moreBtnText: consentBannerFormState.buttonColors.moreBtnText,
                secondLayerTab: consentBannerFormState.generalColors.tabColor,
                tabsBorderColor:
                    consentBannerFormState.generalColors.accentColor,
                text: consentBannerFormState.generalColors.textColor,
                toggleActiveBackground:
                    consentBannerFormState.toggleColors.activeToggleBackground,
                toggleActiveIcon:
                    consentBannerFormState.toggleColors.activeToggleIcon,
                toggleDisabledBackground:
                    consentBannerFormState.toggleColors
                        .disabledToggleBackground,
                toggleDisabledIcon:
                    consentBannerFormState.toggleColors.disabledToggleIcon,
                toggleInactiveBackground:
                    consentBannerFormState.toggleColors
                        .inactiveToggleBackground,
                toggleInactiveIcon:
                    consentBannerFormState.toggleColors.inactiveToggleIcon
            },
            font: { family: null, size: null },
            logoAltTag: null,
            logoUrl: null,
            overlayOpacity:
                consentBannerFormState.advancedSettings.backgroundOverlay &&
                consentBannerFormState.advancedSettings.overlayOpacity
                    ? intToFloatingPoint(
                          parseInt(
                              consentBannerFormState.advancedSettings
                                  .overlayOpacity,
                              10
                          )
                      )
                    : null,
            privacyButtonSizeDesktop: null,
            privacyButtonSizeMobile: null,
            useBackgroundShadow:
                consentBannerFormState.advancedSettings.useBackgroundShadow
        }
    };

    return payload;
};
