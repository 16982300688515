import { useTranslation } from 'react-i18next';

import { SectionDetails } from './SectionDetails';

export const ThirdPartyAppsSectionDetails = () => {
    const { t } = useTranslation();

    return (
        <SectionDetails
            title={t('reportPage.sectionDetailsTitle')}
            paragraph1={t('componentsPage.thirdPartyAppsSectionDetailsDesc')}
        />
    );
};
