export const ThemeDark = () => (
    <svg
        width="110"
        height="80"
        viewBox="0 0 110 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_5624_4127)">
            <rect x="16" y="15" width="78" height="48" rx="4" fill="#141414" />
            <rect x="20" y="27" width="9" height="2" rx="1" fill="#2EA7FF" />
            <rect x="31" y="27" width="9" height="2" rx="1" fill="#2EA7FF" />
            <rect x="42" y="27" width="9" height="2" rx="1" fill="#2EA7FF" />
            <rect x="20" y="34" width="70" height="2" fill="#4F4F4F" />
            <rect x="20" y="38" width="70" height="2" fill="#4F4F4F" />
            <rect x="20" y="42" width="70" height="2" fill="#4F4F4F" />
            <rect x="20" y="46" width="46" height="2" fill="#4F4F4F" />
            <rect
                x="20.5"
                y="53.5"
                width="21"
                height="5"
                rx="0.5"
                stroke="#2EA7FF"
            />
            <rect
                x="44.5"
                y="53.5"
                width="21"
                height="5"
                rx="0.5"
                stroke="#2EA7FF"
            />
            <rect x="68" y="53" width="22" height="6" rx="1" fill="#2EA7FF" />
        </g>
        <defs>
            <filter
                id="filter0_d_5624_4127"
                x="0"
                y="0"
                width="110"
                height="80"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_5624_4127"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_5624_4127"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
