import { Button, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

type Props = {
    index: number;
    handleGoToScanResult: () => void;
};

export const NotificationScanCompleted = ({
    index,
    handleGoToScanResult
}: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <Text fontSize="15px" fontWeight={600}>
                {t('notifications.scanCompleted')}
            </Text>
            {index === 0 ? (
                <>
                    <Text fontSize="15px" fontWeight={400}>
                        {t('notifications.lastScanResultDesc')}
                    </Text>
                    <Button
                        variant="transparent"
                        px="0"
                        onClick={handleGoToScanResult}
                    >
                        {t('notifications.lastScanResultText')}
                    </Button>
                </>
            ) : null}
        </>
    );
};
