import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { setUpClientSettingsId } from '../api/api';
import {
    appConfigStoreActions,
    clientSettingsStoreActions,
    useAuthStore,
    useClientSettingsStore
} from '../stores';

export const useClientSettings = () => {
    const { appInstanceId } = useAuthStore();
    const { clientSettingsId: storedClientSettingsId } =
        useClientSettingsStore();
    const { t } = useTranslation();

    const {
        data: clientSettingsId,
        mutate: setUpClientSettingsIdMutation,
        isLoading: isSetUpClientLoading,
        error
    } = useMutation({
        mutationFn: setUpClientSettingsId,
        cacheTime: 0
    });

    useEffect(() => {
        if (appInstanceId && !storedClientSettingsId) {
            setUpClientSettingsIdMutation({ appInstanceId });
        }
    }, [appInstanceId, setUpClientSettingsIdMutation, storedClientSettingsId]);

    useEffect(() => {
        if (clientSettingsId) {
            clientSettingsStoreActions.setClientSettingsId(clientSettingsId);
        }
    }, [clientSettingsId]);

    useEffect(() => {
        clientSettingsStoreActions.setClientSettingsLoading(
            isSetUpClientLoading
        );
    }, [isSetUpClientLoading]);

    useEffect(() => {
        if (error) {
            appConfigStoreActions.setAppError({
                hasError: true,
                reason: 'noSettingsId'
            });
        }
    }, [error, t]);
};
