import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getScanResult, ScanParams } from '../api/api';
import { ScanStatus } from '../api/generated-types';
import {
    reportStoreActions,
    useAuthStore,
    useClientSettingsStore,
    useReportStore
} from '../stores';
import { toMinutes } from '../util/generalUtils';

export const useReport = () => {
    const { appInstanceId, domain, isLoading: isAuthLoading } = useAuthStore();
    const { clientSettingsId } = useClientSettingsStore();
    const client = useQueryClient();
    const toast = useToast();
    const { t } = useTranslation();
    const { status } = useReportStore();

    const isReFetched = useRef(false);

    const scanResultParams: ScanParams = useMemo(
        () => ({
            appInstanceId,
            domainUrl: domain
        }),
        [appInstanceId, domain]
    );

    const { data, isLoading, error } = useQuery({
        queryKey: ['scanReport', scanResultParams],
        queryFn: () => getScanResult(scanResultParams),
        refetchInterval: () =>
            status === ScanStatus.Pending ? toMinutes(1) : false,
        refetchIntervalInBackground: true,
        cacheTime: 0,
        enabled: !!clientSettingsId && !isAuthLoading,
        retryOnMount: false,
        onSettled: (finalData) => {
            if (
                finalData?.status !== ScanStatus.Pending &&
                isReFetched.current
            ) {
                client
                    .invalidateQueries(['getNotifications', appInstanceId])
                    .then(() => {
                        toast({
                            title: t('generalMessages.newNotifications'),
                            status: 'info',
                            duration: null,
                            isClosable: true
                        });
                    });
            }

            isReFetched.current = true;
        }
    });

    useEffect(() => {
        reportStoreActions.setIsLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (data) {
            reportStoreActions.setReportData(data);
            reportStoreActions.setStatus(data.status);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            reportStoreActions.setError((error as any).error);
            reportStoreActions.setStatus(ScanStatus.Failed);
        }
    }, [error]);
};
