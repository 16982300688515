import { Accordion, Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { FaqItem } from '../FaqItem';

export const FaqSection = () => {
    const { t } = useTranslation();

    return (
        <>
            <Text fontSize={21} fontWeight={600} mb="16px">
                {t('dashboard.faqs.title')}
            </Text>
            <Accordion allowToggle reduceMotion>
                <Stack spacing={2}>
                    <FaqItem title={t('dashboard.faqs.faq1.title')}>
                        {t('dashboard.faqs.faq1.content')}
                    </FaqItem>
                    <FaqItem title={t('dashboard.faqs.faq2.title')}>
                        {t('dashboard.faqs.faq2.content')}
                    </FaqItem>
                    <FaqItem title={t('dashboard.faqs.faq3.title')}>
                        {t('dashboard.faqs.faq3.content')}
                    </FaqItem>
                    <FaqItem title={t('dashboard.faqs.faq4.title')}>
                        <Box>
                            <Text>
                                <Box
                                    as="a"
                                    color="blue"
                                    href="https://wix-support.cookiebot.com/hc/en-us"
                                    target="_blank"
                                >
                                    {t('dashboard.faqs.faq4.content')}{' '}
                                </Box>
                            </Text>
                        </Box>
                        <Box fontStyle="italic">
                            {t('dashboard.faqs.faq4.notice')}
                        </Box>
                    </FaqItem>
                </Stack>
            </Accordion>
        </>
    );
};
