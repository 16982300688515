import { Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useReportStore } from '../../stores';
import { formatDate } from '../../util/generalUtils';

export const ScanStatusNextSchedule = () => {
    const { nextScanDate } = useReportStore();
    const { t } = useTranslation();

    return (
        <Text color="#666666" fontSize="13px">
            {t('dashboard.scanOverview.scanStatusDone.nextScan')}:{' '}
            {formatDate(nextScanDate)}
        </Text>
    );
};
