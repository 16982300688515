import classNames from 'classnames';

import { useAppConfigStore } from '../../stores';
import { Loader } from '../Loader';
import styles from './FullPageLoader.module.css';

export const FullPageLoader = () => {
    const { appLoading } = useAppConfigStore();

    return (
        <div
            className={classNames(styles.root, {
                [styles.visible]: appLoading
            })}
        >
            <div className={styles.loader}>
                <Loader />
            </div>
        </div>
    );
};
