import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
    getLanguagesConfiguration,
    getLanguageTranslations,
    getLanguageTranslationsLegalNotice,
    getSupportedLanguages,
    setLanguagesConfiguration,
    setLanguageTranslations
} from '../../api/api';
import { languagesList } from './contentUtils';

type Props = {
    appInstanceId: string;
    selectedLanguage: string;
};

export const useContentQueries = ({
    appInstanceId,
    selectedLanguage
}: Props) => {
    const queryClient = useQueryClient();

    const {
        data: supportedLanguagesData,
        isLoading: isGetSupportedLanguagesLoading
    } = useQuery({
        queryKey: ['supportedLanguages'],
        queryFn: getSupportedLanguages
    });

    const supportedLanguages = useMemo(() => {
        if (supportedLanguagesData?.length) {
            return supportedLanguagesData.map((language) => ({
                label:
                    languagesList.find((lang) => lang.value === language)!
                        .label || language,
                value: language
            }));
        }

        return [];
    }, [supportedLanguagesData]);

    const { data: languagesConfigData, isLoading: languagesConfigDataLoading } =
        useQuery({
            queryKey: ['languagesConfiguration', appInstanceId],
            queryFn: () => getLanguagesConfiguration(appInstanceId),
            cacheTime: 0,
            enabled: !isGetSupportedLanguagesLoading
        });

    const {
        mutateAsync: setLanguagesConfig,
        isLoading: isSetLanguagesConfigLoading
    } = useMutation({
        mutationFn: setLanguagesConfiguration,
        onSuccess: () => {
            queryClient.invalidateQueries([
                'languagesConfiguration',
                appInstanceId
            ]);
        }
    });

    const {
        data: languageTranslationsData,
        isLoading: languageTranslationsLoading
    } = useQuery({
        queryKey: [
            'languageTranslationsData',
            {
                appInstanceId,
                sourceLanguage: 'en',
                targetLanguage: selectedLanguage
            }
        ],
        queryFn: () =>
            getLanguageTranslations({
                appInstanceId,
                sourceLanguage: 'en',
                targetLanguage: selectedLanguage
            }),
        cacheTime: 0,
        enabled: !isGetSupportedLanguagesLoading
    });

    const {
        data: languageTranslationsDataLegalNotice,
        isLoading: languageTranslationsLegalNoticeLoading
    } = useQuery({
        queryKey: [
            'getLanguageTranslationsLegalNotice',
            {
                appInstanceId,
                sourceLanguage: selectedLanguage
            }
        ],
        queryFn: () =>
            getLanguageTranslationsLegalNotice({
                appInstanceId,
                sourceLanguage: selectedLanguage
            }),
        cacheTime: 0,
        enabled: !isGetSupportedLanguagesLoading
    });

    const {
        mutateAsync: setLanguageTranslation,
        isLoading: isSetLanguageTranslationLoading
    } = useMutation({
        mutationFn: setLanguageTranslations
    });

    return {
        supportedLanguages,
        isGetSupportedLanguagesLoading,
        setLanguageTranslation,
        isSetLanguageTranslationLoading,
        languageTranslationsData,
        languageTranslationsLoading,
        setLanguagesConfig,
        languagesConfigData,
        isSetLanguagesConfigLoading,
        languagesConfigDataLoading,
        languageTranslationsDataLegalNotice,
        languageTranslationsLegalNoticeLoading
    };
};
