import { Box, Input, Stack, Textarea } from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TextLabelsFormState } from '../../../pages/Content/types/Content.types';
import { FormUpdate } from '../../../types';
import { InputLabel } from '../../Form/InputLabel';
import { SectionTitle } from '../../Form/SectionTitle';

type Props = {
    title: TextLabelsFormState['bannerIntroMessage']['title'];
    message: TextLabelsFormState['bannerIntroMessage']['bannerMessage'];
} & FormUpdate;

export const BannerIntroMessage = ({ title, message, updateForm }: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.textLabels.bannerIntroMessage'
    });

    const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateForm({
            formSection: 'bannerIntroMessage',
            id: 'title',
            value: event.target.value
        });
    };

    const onBannerMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        updateForm({
            formSection: 'bannerIntroMessage',
            id: 'bannerMessage',
            value: event.target.value
        });
    };

    return (
        <Box>
            <Stack spacing="24px">
                <SectionTitle>{t('title')}</SectionTitle>
                <Box>
                    <InputLabel>{t('titleLabel')}</InputLabel>
                    <Input value={title} onChange={onTitleChange} />
                </Box>
                <Box>
                    <InputLabel>{t('bannerMessageLabel')}</InputLabel>
                    <Textarea
                        value={message}
                        onChange={onBannerMessageChange}
                    />
                </Box>
            </Stack>
        </Box>
    );
};
