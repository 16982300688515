import { proxy, useSnapshot } from 'valtio';

type State = {
    clientSettingsId: string | undefined;
    clientSettingsLoading: boolean;
};

const defaultState: State = {
    clientSettingsId: undefined,
    clientSettingsLoading: false
};

const state = proxy<State>(defaultState);

export const clientSettingsStoreActions = {
    setClientSettingsId: (clientSettingsId: string) => {
        state.clientSettingsId = clientSettingsId;
    },
    setClientSettingsLoading: (clientSettingsLoading: boolean) => {
        state.clientSettingsLoading = clientSettingsLoading;
    }
};

export const useClientSettingsStore = () => useSnapshot<State>(state);
