import {
    Box,
    Flex,
    MdErrorOutline,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { recommendedActionsMap } from '../../util/constants';

type Props = {
    recommendedAction: string;
    isWixComponents: boolean;
};

export const ComponentRecommendedAction = (props: Props) => {
    const { recommendedAction, isWixComponents } = props;

    const { t } = useTranslation();

    return (
        <Box px="24px" py="8px">
            <Flex gap={2} align="center">
                <MdErrorOutline color="#F6A724" fontSize={25} />
                {t('common.recommendedAction')}:
                <Text color="#1032CF" fontWeight={700}>
                    {isWixComponents ? (
                        <Text>
                            {t(
                                'appearancePage.placeholders.enablePlaceholders'
                            )}{' '}
                            <Link
                                to="/banner/blocking"
                                style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                            >
                                here
                            </Link>
                        </Text>
                    ) : (
                        t(recommendedActionsMap[recommendedAction])
                    )}
                </Text>
            </Flex>
        </Box>
    );
};
