import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getIsPaid } from '../api/api';
import {
    appConfigStoreActions,
    premiumStoreActions,
    useAuthStore
} from '../stores';

export const usePremium = () => {
    const { appInstanceId } = useAuthStore();
    const { t } = useTranslation();

    const {
        data: isPaidData,
        isLoading: isPaidLoading,
        error
    } = useQuery({
        queryKey: ['getIsPaid', { appInstanceId }],
        queryFn: () => getIsPaid({ appInstanceId }),
        cacheTime: 0,
        enabled:
            !!appInstanceId &&
            appInstanceId !== '' &&
            appInstanceId !== undefined
    });

    useEffect(() => {
        premiumStoreActions.setIsPremiumLoading(isPaidLoading);
    }, [isPaidLoading]);

    useEffect(() => {
        if (isPaidLoading) {
            premiumStoreActions.setIsPremium(null);

            return;
        }

        premiumStoreActions.setIsPremium(
            !!isPaidData && !isPaidLoading && isPaidData.paid
        );
    }, [isPaidData, isPaidLoading]);

    useEffect(() => {
        if (error) {
            premiumStoreActions.setIsPremium(null);
            appConfigStoreActions.setAppError({
                hasError: true,
                reason: 'premiumFailed'
            });
        }
    }, [error, t]);
};
