import {
    Box,
    ColorPicker,
    Grid,
    Input,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentBannerGeneralColors } from '../../../pages/Appearance/types/consentBanner.type';
import { UpdateFormInput } from '../../../types';

type Props = {
    updateConsentBannerForm: (payload: UpdateFormInput) => void;
    colorsState: ConsentBannerGeneralColors;
};

export const ContentBannerGeneralColors = ({
    updateConsentBannerForm,
    colorsState
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner.generalColors'
    });
    const colorsKeys = useMemo(() => {
        if (colorsState) {
            return Object.keys(colorsState);
        }

        return [];
    }, [colorsState]);

    return (
        <Box>
            <Grid
                templateRows="1fr 1fr 1fr"
                templateColumns="1fr 1fr"
                gap="20px"
            >
                {colorsKeys.map((colorKey) => (
                    <Box key={colorKey}>
                        <Text fontSize={14} fontWeight={600}>
                            {t(colorKey)}
                        </Text>
                        <Box display="flex" gap="8px">
                            <ColorPicker
                                value={colorsState[colorKey] as string}
                                onChange={(event: any) =>
                                    updateConsentBannerForm({
                                        formSection: 'generalColors',
                                        id: colorKey,
                                        value: event.target.value
                                    })
                                }
                                styles={{ cursor: 'pointer' }}
                            />
                            <Input
                                value={colorsState[colorKey] as string}
                                onChange={(event) => {
                                    updateConsentBannerForm({
                                        formSection: 'generalColors',
                                        id: colorKey,
                                        value: event.target.value
                                    });
                                }}
                            />
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    );
};
