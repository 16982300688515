export const BannerMenuIcon = () => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21 3.5H3C1.9 3.5 1 4.4 1 5.5V17.5C1 18.6 1.9 19.5 3 19.5H8V21.5H16V19.5H21C22.1 19.5 23 18.6 23 17.5V5.5C23 4.4 22.1 3.5 21 3.5ZM21 17.5H3V5.5H21V17.5ZM19 8.5H8V10.5H19V8.5ZM19 12.5H8V14.5H19V12.5ZM7 8.5H5V10.5H7V8.5ZM7 12.5H5V14.5H7V12.5Z"
            fill="#141414"
        />
    </svg>
);
