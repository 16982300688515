import { useCallback, useState } from 'react';

import { PrivacyTriggerFormState } from '../pages/Appearance/types/privacyTrigger.type';
import { privacyTriggerInitialFormState } from '../pages/Appearance/utils/appearanceFormStateUtils';
import { UpdateFormInput } from '../types';

export const usePrivacyTriggerFormState = () => {
    const [privacyTriggerFormState, setPrivacyTriggerFormState] =
        useState<PrivacyTriggerFormState>(privacyTriggerInitialFormState);

    const updatePrivacyTriggerForm = useCallback(
        ({ formSection, subSection, value, id }: UpdateFormInput) => {
            setPrivacyTriggerFormState((prevState: any) => {
                if (subSection && formSection) {
                    return {
                        ...prevState,
                        [formSection]: {
                            ...prevState[formSection],
                            [subSection]: {
                                ...prevState[formSection][subSection],
                                [id]: value
                            }
                        }
                    };
                }

                if (formSection) {
                    return {
                        ...prevState,
                        [formSection]: {
                            ...prevState[formSection],
                            [id]: value
                        }
                    };
                }

                return { ...prevState, [id]: value };
            });
        },
        [setPrivacyTriggerFormState]
    );

    return {
        updatePrivacyTriggerForm,
        privacyTriggerFormState,
        setPrivacyTriggerFormState
    };
};
