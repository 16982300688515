import { Divider } from '@chakra-ui/react';
import { Box, Button, Flex, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { FaRegBell } from 'react-icons/fa6';
import { NavigateFunction } from 'react-router-dom';

import { Loader } from '../Loader';
import { NotificationItem } from './NotificationItem';
import { useNotifications } from './useNotifications';

type Props = {
    navigate: NavigateFunction;
};

export const Notifications = ({ navigate }: Props) => {
    const { t } = useTranslation();

    const {
        notifications,
        hasUnreadNotifications,
        showDropdown,
        dropdownRef,
        handleMarkAllAsRead,
        handleMarkNotificationAsRead,
        loading,
        handleOnNotificationClick
    } = useNotifications();

    return (
        <Box position="relative">
            <Button
                onClick={handleOnNotificationClick}
                bg={hasUnreadNotifications ? '#F2F2F2' : 'transparent'}
                _hover={{
                    background: '#F2F2F2'
                }}
            >
                <FaRegBell color="black" size="24px" />
            </Button>
            <Box
                position="absolute"
                top="2"
                right="2"
                bg="#CB1F1F"
                borderRadius="50%"
                w="10px"
                h="10px"
                display={hasUnreadNotifications ? 'block' : 'none'}
            />
            <Box ref={dropdownRef}>
                {showDropdown && (
                    <Box
                        position="absolute"
                        right={0}
                        top="100%"
                        w={400}
                        bg={loading ? 'gray.100' : 'white'}
                        boxShadow="lg"
                        borderRadius="8px"
                        zIndex={1000}
                        border="1px solid #f0f0f0"
                    >
                        {loading ? (
                            <Box
                                position="absolute"
                                left="50%"
                                top="50%"
                                transform="translate(-50%, -50%)"
                                zIndex={1001}
                            >
                                <Loader />
                            </Box>
                        ) : null}

                        <Box
                            display="flex"
                            alignItems="center"
                            gap="12px"
                            mt="18px"
                            mb="24px"
                            pt={4}
                            pr={4}
                            pl={4}
                        >
                            <Box
                                bg="#ECEFFE"
                                px="8px"
                                py="8px"
                                borderRadius="6px"
                            >
                                <FaRegBell color="#1032CF" size="24px" />
                            </Box>
                            <Text fontSize="21px" fontWeight={600}>
                                {t('notifications.title')}
                            </Text>
                        </Box>
                        <Divider />
                        {notifications.map((notification, index) => (
                            <>
                                <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    index={index}
                                    handleMarkNotificationAsRead={
                                        handleMarkNotificationAsRead
                                    }
                                    navigate={navigate}
                                    loading={loading}
                                />
                                <Divider />
                            </>
                        ))}
                        <Flex justifyContent="center">
                            <Button
                                variant="transparent"
                                onClick={handleMarkAllAsRead}
                            >
                                {t('notifications.markAsRead')}
                            </Button>
                        </Flex>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
