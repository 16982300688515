import { extendTheme } from '@chakra-ui/react';
import { theme as defaultTheme } from '@usercentrics/cookiebot-ui-react';

const Container = {
    baseStyle: {
        maxW: 1440
    }
};

export const theme = extendTheme({
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        Container
    }
});
