import {
    Accordion,
    Box,
    Flex,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdErrorOutline,
    MdSearch,
    Tag,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ComponentGroup } from '../../types';
import { ActionRequiredBanner } from '../ActionRequiredBanner';
import { ComponentsCategory } from '../ComponentsCategory';

type Props = {
    heading: string;
    groupedComponents: ComponentGroup[];
    withRisk?: boolean;
    hasConflict?: boolean;
    isComponentsPage?: boolean;
};

export const ComponentsReport = (props: Props) => {
    const {
        groupedComponents,
        heading,
        hasConflict,
        withRisk = false,
        isComponentsPage = false
    } = props;

    const { t } = useTranslation();

    const [search, setSearch] = useState('');

    const filteredComponents = useMemo(() => {
        if (search) {
            return groupedComponents.map((category) => ({
                ...category,
                items: category.items.filter((component) =>
                    component.name.toLowerCase().includes(search.toLowerCase())
                )
            }));
        }

        return groupedComponents;
    }, [groupedComponents, search]);

    return (
        <Box>
            <Flex mb={2} alignItems="center" justifyContent="space-between">
                <Heading as="h3">
                    <Flex align="center" gap={2}>
                        {heading}
                        {withRisk ? (
                            <Tag background="#DE8E09">
                                <Text
                                    display="flex"
                                    alignItems="center"
                                    gap="8px"
                                >
                                    <MdErrorOutline />{' '}
                                    {t('common.actionRequired')}
                                </Text>
                            </Tag>
                        ) : null}
                    </Flex>
                </Heading>
                <InputGroup w="auto">
                    <Input
                        placeholder={t('common.searchForComponents')}
                        onChange={(e: any) => setSearch(e.target.value)}
                        value={search}
                    />
                    <InputRightElement>
                        <Icon as={MdSearch} />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            {hasConflict ? (
                <Box mb="4px">
                    <ActionRequiredBanner />
                </Box>
            ) : null}
            <Accordion
                allowMultiple
                reduceMotion
                size="lg"
                display="flex"
                flexDirection="column"
                gap={1}
            >
                {filteredComponents.map((group) => (
                    <ComponentsCategory
                        isComponentsPage={isComponentsPage}
                        group={group}
                        withRisk={withRisk}
                        key={`${withRisk ? 'risk-' : ''}component-category-${
                            group.name
                        }`}
                    />
                ))}
            </Accordion>
        </Box>
    );
};
