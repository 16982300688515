import defaultClasses from './Loader.module.css';

type Props = {
    width?: string;
    height?: string;
};

export const Loader = ({ width, height }: Props) => (
    <span
        style={{ width: `${width}px`, height: `${height}px` }}
        className={defaultClasses.loader}
    />
);
