import { Box, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { ScanLoader } from './ScanLoader/ScanLoader';
import defaultClasses from './ScanStatus.module.css';

export const ScanStatusPending = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" justifyContent="center" mt={5} mb={10}>
                    <ScanLoader />
                </Box>
                <Text
                    fontSize="15px"
                    fontWeight={600}
                    color="#1032CF"
                    mb="24px"
                >
                    {t('dashboard.scanOverview.scanStatusPending.loadingMsg')}
                </Text>
                <Text>
                    {t('dashboard.scanOverview.scanStatusPending.description')}
                </Text>
            </Box>
            <Box className={defaultClasses.pendingNotice}>
                {t('dashboard.scanOverview.scanStatusPending.notice')}
            </Box>
        </>
    );
};
