import {
    Box,
    MdErrorOutline,
    Tag,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { MdOutlineRadar } from 'react-icons/md';

import { useReportStore } from '../../stores';
import { StatusEnum, Texts } from '../../util/constants';
import { formatDate } from '../../util/generalUtils';

const ColorMap: Record<StatusEnum.DoneAtRisk | StatusEnum.Failed, string> = {
    [StatusEnum.DoneAtRisk]: '#DE8E09',
    [StatusEnum.Failed]: '#9F1818'
};

const TextMap: Record<StatusEnum.DoneAtRisk | StatusEnum.Failed, string> = {
    [StatusEnum.DoneAtRisk]: Texts.ActionRequired,
    [StatusEnum.Failed]: Texts.Error
};

type Props = {
    status?: string;
};

export const ScanStatusLastScan = ({ status }: Props) => {
    const { lastScanDate } = useReportStore();
    const { t } = useTranslation();
    const isTagVisible =
        status &&
        (status === StatusEnum.DoneAtRisk || status === StatusEnum.Failed);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
        >
            <Box display="flex" gap="16px">
                <Box
                    bg="white"
                    w="48px"
                    h="48px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="6px"
                >
                    <MdOutlineRadar size={20} />
                </Box>
                {lastScanDate ? (
                    <Box>
                        <Text fontSize="13px" color="#666666">
                            {t(
                                'dashboard.scanOverview.scanStatusDone.lastScan.label'
                            )}
                        </Text>
                        <Text fontSize="18px" fontWeight={600} color="#141414">
                            {formatDate(lastScanDate)}
                        </Text>
                    </Box>
                ) : null}
            </Box>
            <Box>
                {isTagVisible ? (
                    <Tag background={ColorMap[status]}>
                        <Text display="flex" alignItems="center" gap="8px">
                            <MdErrorOutline fontSize={16} />{' '}
                            {t(TextMap[status])}
                        </Text>
                    </Tag>
                ) : null}
            </Box>
        </Box>
    );
};
