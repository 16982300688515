import { Divider } from '@chakra-ui/react';
import { ReportHeader } from '@components/ReportHeader';
import { ReportIdentifiedComponents } from '@components/ReportIdentifiedComponents';
import { ReportLegalNote } from '@components/ReportLegalNote';
import { ReportUnknownComponents } from '@components/ReportUnknownComponents';
import { Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useReportStore } from '../stores';
import { StatusEnum } from '../util/constants';

export const Report = () => {
    const { status } = useReportStore();

    const { t } = useTranslation();

    if (status !== StatusEnum.Done) {
        return <Navigate to="/" replace />;
    }

    return (
        <Stack spacing="40px">
            <Text fontSize="60px" fontWeight={800}>
                {t('reportPage.title')}
            </Text>
            <ReportHeader />
            <ReportIdentifiedComponents />
            <ReportUnknownComponents />
            <Divider />
            <ReportLegalNote />
        </Stack>
    );
};
