import { Divider } from '@chakra-ui/react';
import { BannerSection } from '@components/Navigation/BannerSection';
import { SupportSection } from '@components/Navigation/SupportSection';
import {
    MdFingerprint,
    MdOutlineDashboard,
    Nav
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppConfigStore, useReportStore } from '../../stores';
import { StatusEnum } from '../../util/constants';
import { NavLink } from '../NavLink';

export const Navigation = () => {
    const { t } = useTranslation();
    const { status } = useReportStore();
    const [highlightParent, setHighlightParent] = useState<string | null>(null);
    const { appError } = useAppConfigStore();

    return (
        <Nav>
            <NavLink
                href="/dashboard"
                label={t('routes.dashboard')}
                icon={<MdOutlineDashboard />}
                setHighlightParent={setHighlightParent}
            />
            <NavLink
                disabled={status !== StatusEnum.Done}
                href="/report"
                label={t('routes.report')}
                icon={<MdFingerprint />}
                setHighlightParent={setHighlightParent}
            />
            <Divider />
            <BannerSection
                setHighlightParent={setHighlightParent}
                status={status}
                appError={appError}
                highlightParent={highlightParent}
            />
            <Divider />
            <SupportSection
                setHighlightParent={setHighlightParent}
                status={status}
            />
        </Nav>
    );
};
