import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getAppearanceConfiguration,
    setAppearanceConfiguration
} from '../api/api';
import { ConsentBannerFormState } from '../pages/Appearance/types/consentBanner.type';
import { PrivacyTriggerFormState } from '../pages/Appearance/types/privacyTrigger.type';
import {
    mapFormStateToPayload,
    mapResponseToConsentBannerFormState,
    mapResponseToPrivacyTriggerFormState
} from '../pages/Appearance/utils/appearanceUtils';
import { useAuthStore } from '../stores';
import { deepCopy, deepEqual } from '../util/generalUtils';
import { useAmplitude } from './useAmplitude';
import { useConsentBannerForm } from './useConsentBannerForm';
import { usePrivacyTriggerFormState } from './usePrivacyTriggerFormState';

export const useAppearance = () => {
    const { appInstanceId } = useAuthStore();
    const toast = useToast();
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const {
        setConsentBannerFormState,
        updateConsentBannerForm,
        consentBannerFormState
    } = useConsentBannerForm();

    const {
        updatePrivacyTriggerForm,
        privacyTriggerFormState,
        setPrivacyTriggerFormState
    } = usePrivacyTriggerFormState();

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
        useState<boolean>(true);

    const consentBannerFormRef = useRef<ConsentBannerFormState>();
    const privacyTriggerFormRef = useRef<PrivacyTriggerFormState>();

    const [loading, setLoading] = useState(true);

    const { data: appearanceData } = useQuery({
        queryKey: ['getAppearanceConfiguration', appInstanceId],
        queryFn: () => getAppearanceConfiguration(appInstanceId),
        cacheTime: 0,
        enabled:
            appInstanceId !== '' &&
            appInstanceId !== null &&
            appInstanceId !== undefined
    });

    const { mutate: setConfiguration, isLoading: isSetConfigLoading } =
        useMutation({
            mutationFn: setAppearanceConfiguration
        });

    useEffect(() => {
        if (consentBannerFormRef.current && privacyTriggerFormRef.current) {
            if (
                !deepEqual(
                    consentBannerFormState,
                    consentBannerFormRef.current
                ) ||
                !deepEqual(
                    privacyTriggerFormState,
                    privacyTriggerFormRef.current
                )
            ) {
                setIsSaveButtonDisabled(false);
            } else {
                setIsSaveButtonDisabled(true);
            }
        }
    }, [consentBannerFormState, privacyTriggerFormState]);

    useEffect(() => {
        if (appearanceData) {
            const mappedConsentBannerData =
                mapResponseToConsentBannerFormState(appearanceData);
            const mappedPrivacyTriggerData =
                mapResponseToPrivacyTriggerFormState(appearanceData);
            setConsentBannerFormState(mappedConsentBannerData);
            setPrivacyTriggerFormState(mappedPrivacyTriggerData);

            consentBannerFormRef.current = deepCopy(mappedConsentBannerData);
            privacyTriggerFormRef.current = deepCopy(mappedPrivacyTriggerData);
            setLoading(false);
        }
    }, [appearanceData, setConsentBannerFormState, setPrivacyTriggerFormState]);

    const handleOnSubmit = () => {
        sendEvent('Update banner configuration - Appearance');

        if (consentBannerFormState && privacyTriggerFormState) {
            const payload = mapFormStateToPayload({
                consentBannerFormState,
                privacyTriggerFormState
            });
            setConfiguration(
                {
                    appInstanceId,
                    payload
                },
                {
                    onSuccess: () => {
                        consentBannerFormRef.current = deepCopy(
                            consentBannerFormState
                        );
                        privacyTriggerFormRef.current = deepCopy(
                            privacyTriggerFormState
                        );
                        setIsSaveButtonDisabled(true);
                        toast({
                            title: t('appearancePage.successMessage'),
                            status: 'info',
                            duration: 3000,
                            isClosable: true
                        });
                    },
                    onError: () => {
                        toast({
                            title: t('appearancePage.errorMessage'),
                            status: 'error',
                            duration: 3000,
                            isClosable: true
                        });
                    }
                }
            );
        }
    };

    const handleOnReset = () => {
        if (
            !isSaveButtonDisabled &&
            consentBannerFormRef.current &&
            privacyTriggerFormRef.current
        ) {
            setConsentBannerFormState(deepCopy(consentBannerFormRef.current));
            setPrivacyTriggerFormState(deepCopy(privacyTriggerFormRef.current));
        }
    };

    return {
        loading,
        handleOnSubmit,
        handleOnReset,
        isSaveButtonDisabled,
        isSetConfigLoading,
        consentBannerFormState,
        setConsentBannerFormState,
        updateConsentBannerForm,
        updatePrivacyTriggerForm,
        privacyTriggerFormState
    };
};
