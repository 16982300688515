import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel
} from '@usercentrics/cookiebot-ui-react';
import { ReactNode } from 'react';

import { useAmplitude } from '../../hooks';

type FaqItemProps = {
    title: string;
    children: ReactNode;
};

export const FaqItem = (props: FaqItemProps) => {
    const { title, children } = props;

    const { sendEvent } = useAmplitude();

    const handleOnClick = () => {
        sendEvent(`Read FAQ - ${title}`);
    };

    return (
        <AccordionItem px="24px" onClick={handleOnClick}>
            <AccordionButton
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                textAlign="left"
                px={0}
            >
                {title}
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel mx={0} my={0} borderTopWidth={1}>
                {children}
            </AccordionPanel>
        </AccordionItem>
    );
};
