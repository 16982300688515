import { Divider } from '@chakra-ui/react';
import { Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentBannerFormState } from '../../pages/Appearance/types/consentBanner.type';
import { UpdateFormInput } from '../../types';
import { ColorsAccordionWrapper } from '../ColorsAccordionWrapper/ColorsAccordionWrapper';
import { ConsentBannerBackgroundOverlay } from './AdvancedSettings/ConsentBannerBackgroundOverlay';
import { ConsentBannerBackgroundShadow } from './AdvancedSettings/ConsentBannerBackgroundShadow';
import { ConsentBannerButtonsColors } from './ConsentBannerButtonsColors/ConsentBannerButtonsColors';
import { ConsentBannerToggleColors } from './ConsentBannerToggleColors/ConsentBannerToggleColors';
import { ContentBannerGeneralColors } from './ContentBannerGeneralColors/ContentBannerGeneralColors';
import { ContentBannerTheme } from './ContentBannerTheme/ContentBannerTheme';
import { LayoutType } from './LayoutType/LayoutType';

type LayoutTabTypes = {
    consentBannerFormState: ConsentBannerFormState;
    setConsentBannerFormState: Dispatch<SetStateAction<ConsentBannerFormState>>;
    updateConsentBannerForm: (payload: UpdateFormInput) => void;
};

export const ConsentBannerTab = ({
    consentBannerFormState,
    setConsentBannerFormState,
    updateConsentBannerForm
}: LayoutTabTypes) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner'
    });

    return (
        <Box>
            <Stack spacing="40px">
                <Box
                    backgroundColor="#F2F2F2"
                    borderRadius="16px"
                    px="40px"
                    py="40px"
                >
                    <Stack spacing="2">
                        <Text fontSize="28px" fontWeight="700">
                            {t('title')}
                        </Text>
                        <Text fontSize="15px" color="#141414">
                            {t('subTitle')}
                        </Text>
                    </Stack>
                </Box>
                <Stack spacing="32px">
                    <LayoutType
                        layoutType={consentBannerFormState.layoutType}
                        setConsentBannerFormState={setConsentBannerFormState}
                    />
                    <Divider />
                    <ContentBannerTheme
                        theme={consentBannerFormState.theme}
                        setConsentBannerFormState={setConsentBannerFormState}
                    />
                    {consentBannerFormState.theme === 'custom' ? (
                        <Stack spacing="16px">
                            <ColorsAccordionWrapper
                                expandedByDefault
                                title={t('generalColors.title')}
                            >
                                <ContentBannerGeneralColors
                                    updateConsentBannerForm={
                                        updateConsentBannerForm
                                    }
                                    colorsState={
                                        consentBannerFormState.generalColors
                                    }
                                />
                            </ColorsAccordionWrapper>
                            <ColorsAccordionWrapper
                                title={t('buttonColors.title')}
                            >
                                <ConsentBannerButtonsColors
                                    updateForm={updateConsentBannerForm}
                                    colors={consentBannerFormState.buttonColors}
                                />
                            </ColorsAccordionWrapper>
                            <ColorsAccordionWrapper
                                title={t('toggleColors.title')}
                            >
                                <ConsentBannerToggleColors
                                    updateForm={updateConsentBannerForm}
                                    togglesFormState={
                                        consentBannerFormState.toggleColors
                                    }
                                />
                            </ColorsAccordionWrapper>
                            <ColorsAccordionWrapper
                                title={t('advancedSettings.title')}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="start"
                                    gap="24px"
                                >
                                    <ConsentBannerBackgroundOverlay
                                        advancedSettingsState={
                                            consentBannerFormState.advancedSettings
                                        }
                                        updateConsentBannerForm={
                                            updateConsentBannerForm
                                        }
                                    />
                                    <ConsentBannerBackgroundShadow
                                        onChange={(event: any) =>
                                            updateConsentBannerForm({
                                                formSection: 'advancedSettings',
                                                id: 'useBackgroundShadow',
                                                value: event.target.checked
                                            })
                                        }
                                        isChecked={
                                            consentBannerFormState
                                                .advancedSettings
                                                .useBackgroundShadow
                                        }
                                    />
                                </Box>
                            </ColorsAccordionWrapper>
                        </Stack>
                    ) : null}
                </Stack>
            </Stack>
        </Box>
    );
};
