import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from '@usercentrics/cookiebot-ui-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

import { ScanParams, startScan } from '../../api/api';
import { reportStoreActions, useAuthStore } from '../../stores';
import { StatusEnum } from '../../util/constants';

type Props = {
    status: string;
};

export const ScanStatusStartButton = ({ status }: Props) => {
    const { t } = useTranslation();
    const { domain, appInstanceId } = useAuthStore();
    const client = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: startScan,
        onSuccess: () => {
            client.invalidateQueries([
                'scanReport',
                {
                    appInstanceId,
                    domainUrl: domain
                }
            ]);
        }
    });

    const startScanParams: ScanParams = useMemo(
        () => ({
            appInstanceId,
            domainUrl: domain
        }),
        [domain, appInstanceId]
    );

    const handleOnScanStart = () => {
        if (status === StatusEnum.Pending) {
            return;
        }

        reportStoreActions.setStatus(StatusEnum.Pending);

        if (appInstanceId === 'Test') {
            return;
        }

        mutate(startScanParams);
    };

    return (
        <Button onClick={handleOnScanStart}>
            {t('dashboard.scanOverview.scanStatusStart.startScan')}
            <MdOutlineKeyboardArrowRight fontSize="21px" />
        </Button>
    );
};
