import { useToast } from '@chakra-ui/react';
import { InputLabel } from '@components/Form/InputLabel';
import { useMutation } from '@tanstack/react-query';
import {
    Box,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Stack
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';

import { startScan } from '../../api/api';
import { useForm } from '../../hooks/useForm';

export const ScanAnySite = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { update, formState, reset } = useForm({
        appInstanceId: '',
        domain: ''
    });

    const toast = useToast();

    const { mutate: startScanMutation } = useMutation({
        mutationFn: startScan,
        onError: () => {
            toast({
                title: 'Error',
                description: 'Error while starting scan',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        },
        onSuccess: () => {
            toast({
                title: 'Scan started',
                description: 'The scan has been started',
                status: 'success',
                duration: 5000,
                isClosable: true
            });
        }
    });

    const handleCloseModal = () => {
        reset();
        setIsOpen(false);
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleStartScan = () => {
        if (!formState.appInstanceId || !formState.domain) {
            toast({
                title: 'Error',
                description: 'Please fill out all fields',
                status: 'error',
                duration: 5000,
                isClosable: true
            });

            return;
        }

        startScanMutation({
            appInstanceId: formState.appInstanceId,
            domainUrl: formState.domain
        });
    };

    return (
        <>
            <Button onClick={handleOpenModal}>Scan specific site</Button>
            <Modal
                isOpen={isOpen}
                onClose={handleCloseModal}
                id="rescan-site-modal"
            >
                <ModalOverlay />
                <ModalContent px="32px" pt="24px" pb="40px">
                    <ModalBody>
                        <Stack
                            spacing="24px"
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            px="24px"
                            py="24px"
                        >
                            <Box>
                                <InputLabel>App instance id</InputLabel>
                                <Input
                                    value={formState.appInstanceId}
                                    onChange={(event) =>
                                        update({
                                            id: 'appInstanceId',
                                            value: event.target.value
                                        })
                                    }
                                />
                            </Box>
                            <Box>
                                <InputLabel>Domain</InputLabel>
                                <Input
                                    value={formState.domain}
                                    onChange={(event) =>
                                        update({
                                            id: 'domain',
                                            value: event.target.value
                                        })
                                    }
                                />
                            </Box>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleCloseModal} variant="outline">
                            Close
                        </Button>
                        <Button onClick={handleStartScan}>Start</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
