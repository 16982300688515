import { DefaultContent } from '@components/LeaveReviewModal/DefaultContent';
import { ThumbsDownContent } from '@components/LeaveReviewModal/ThumbsDownContent';
import { ThumbsUpContent } from '@components/LeaveReviewModal/ThumbsUpContent';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack
} from '@usercentrics/cookiebot-ui-react';
import { useEffect, useRef, useState } from 'react';

import {
    appConfigStoreActions,
    useAppConfigStore,
    useAuthStore,
    usePremiumStore
} from '../../stores';
import { FeedbackModalView } from '../../util/constants';
import { toMinutes } from '../../util/generalUtils';

const reviewModalKey = 'show-review-modal';

const timeouts: NodeJS.Timeout[] = [];

export const LeaveReviewModal = () => {
    const { appInstanceId } = useAuthStore();
    const { isFeedbackModalOpen, isScriptEnabled } = useAppConfigStore();
    const { isPremium } = usePremiumStore();
    const [view, setView] = useState<FeedbackModalView>(
        FeedbackModalView.Default
    );
    const [isAutoDisplayed, setIsAutoDisplayed] = useState(false);

    const runAutoDisplay = useRef(true);

    const onClose = () => {
        appConfigStoreActions.setIsFeedbackModalOpen(false);
        setView(FeedbackModalView.Default);
        setIsAutoDisplayed(false);
    };

    useEffect(() => {
        if (isFeedbackModalOpen) {
            timeouts.forEach((timeout) => {
                clearTimeout(timeout);
            });
            runAutoDisplay.current = false;
        }
    }, [isFeedbackModalOpen]);

    useEffect(() => {
        if (appInstanceId) {
            const showReviewModal = localStorage.getItem(
                `${reviewModalKey}-${appInstanceId}`
            );

            if (showReviewModal === 'false') {
                return;
            }

            if (isScriptEnabled && isPremium && runAutoDisplay.current) {
                const timeoutID = setTimeout(() => {
                    setIsAutoDisplayed(true);
                    appConfigStoreActions.setIsFeedbackModalOpen(true);
                    runAutoDisplay.current = false;
                }, toMinutes(5));

                timeouts.push(timeoutID);
            }
        }
    }, [appInstanceId, isPremium, isScriptEnabled]);

    return (
        <Modal
            isOpen={isFeedbackModalOpen}
            onClose={onClose}
            id="upgrade-premium-modal"
        >
            <ModalOverlay />
            <ModalContent px="32px" pt="24px" w="50%">
                <ModalHeader>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Stack
                        spacing="24px"
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mt={isAutoDisplayed ? 6 : 12}
                        pb={isAutoDisplayed ? 0 : 12}
                    >
                        {view === FeedbackModalView.Default && (
                            <DefaultContent
                                onClose={onClose}
                                onViewChange={setView}
                                isAutoDisplayed={isAutoDisplayed}
                            />
                        )}
                        {view === FeedbackModalView.ThumbsUp && (
                            <ThumbsUpContent onClose={onClose} />
                        )}
                        {view === FeedbackModalView.ThumbsDown && (
                            <ThumbsDownContent onClose={onClose} />
                        )}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
