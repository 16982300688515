import {
    Box,
    ColorPicker,
    Input,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { PrivacyTriggerFormState } from '../../../pages/Appearance/types/privacyTrigger.type';
import { FormUpdate } from '../../../types';

type Props = FormUpdate & {
    background: PrivacyTriggerFormState['iconColors']['background'];
    icon: PrivacyTriggerFormState['iconColors']['icon'];
    formSection: string;
};

export const PrivacyTriggerIconColors = ({
    updateForm,
    background,
    icon,
    formSection
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.privacyTrigger'
    });

    return (
        <Box>
            <Stack spacing={4}>
                <Box display="flex" gap={4}>
                    <Box>
                        <Text fontSize="15px" fontWeight={600}>
                            {t('colors.backgroundColor')}
                        </Text>
                        <Box display="flex" gap="8xp">
                            <ColorPicker
                                defaultValue={background as string}
                                value={background as string}
                                onChange={(event: any) =>
                                    updateForm({
                                        formSection,
                                        id: 'background',
                                        value: event.target.value
                                    })
                                }
                            />
                            <Input
                                value={background as string}
                                onChange={(event: any) =>
                                    updateForm({
                                        formSection,
                                        id: 'background',
                                        value: event.target.value
                                    })
                                }
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Text fontSize="15px" fontWeight={600}>
                            {t('colors.iconColor')}
                        </Text>
                        <Box display="flex" gap="8px">
                            <ColorPicker
                                defaultValue={icon as string}
                                value={icon as string}
                                onChange={(event: any) =>
                                    updateForm({
                                        formSection,
                                        id: 'icon',
                                        value: event.target.value
                                    })
                                }
                            />
                            <Input
                                value={icon as string}
                                onChange={(event: any) =>
                                    updateForm({
                                        formSection,
                                        id: 'icon',
                                        value: event.target.value
                                    })
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </Box>
    );
};
