import { useEffect, useState } from 'react';

import { adminStoreActions } from '../stores';

export const useAdmin = () => {
    const [enteredSequence, setEnteredSequence] = useState<string>('');

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            const key = event.key.toLowerCase();
            const adminCode = 'imanadmin';

            setEnteredSequence((prevSequence) => prevSequence + key);

            if ((enteredSequence + key).includes(adminCode)) {
                adminStoreActions.setShowAdminModal(true);
                setEnteredSequence('');
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [enteredSequence]);
};
