import { Divider } from '@chakra-ui/react';
import {
    Box,
    Button,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdSearch,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    MultiSelect,
    MultiSelectButton,
    MultiSelectList,
    MultiSelectOption,
    Stack,
    Switch,
    Text,
    Textarea
} from '@usercentrics/cookiebot-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormState } from '../../hooks';
import { Component } from '../../types';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    component: Component | null;
};

const urls = [
    'https://www.cookiebot.com/custom-mapping-url',
    'https://www.cookiebot.com/custom-mapping-url2',
    'https://www.cookiebot.com/custom-mapping-url3',
    'https://www.cookiebot.com/custom-mapping-url4'
];

type FormState = {
    title: string;
    description: string;
    selectedUrls: string[];
    showInLiveBanner: boolean;
};

const defaultFormState: FormState = {
    title: '',
    description: '',
    selectedUrls: [],
    showInLiveBanner: false
};

export const CustomEmbedModal = (props: Props) => {
    const { isOpen, onClose, component } = props;
    const { t } = useTranslation();

    const [formState, setFormState] = useState<FormState>(defaultFormState);
    const [search, setSearch] = useState('');

    const { updateForm } = useFormState();

    const filteredUrls = useMemo(
        () =>
            urls.filter((url) =>
                url.toLowerCase().includes(search.toLowerCase())
            ),
        [search]
    );

    const handleOnMappingChange = (event: any) => {
        updateForm(setFormState, {
            id: 'selectedUrls',
            value: event.target.value
        });
    };

    useEffect(() => {
        if (component) {
            setFormState({
                title: component.title ?? '',
                description: component.description,
                selectedUrls: component.mappedUrls ?? [],
                showInLiveBanner: component.showInLiveBanner ?? false
            });
        }
    }, [component]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {t('componentsPage.customEmbeds.editComponent')}
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Stack spacing="24px">
                        <Box>
                            <Text fontWeight={600}>
                                {t('componentsPage.customEmbeds.titleInBanner')}
                            </Text>
                            <Input
                                value={formState.title}
                                onChange={(event) =>
                                    updateForm(setFormState, {
                                        id: 'title',
                                        value: event.target.value
                                    })
                                }
                                placeholder={t(
                                    'componentsPage.customEmbeds.titleInBannerPlaceholder'
                                )}
                            />
                        </Box>
                        <Box>
                            <Text fontWeight={600}>
                                {t('componentsPage.customEmbeds.descInBanner')}
                            </Text>
                            <Textarea
                                value={formState.description}
                                onChange={(event) => {
                                    updateForm(setFormState, {
                                        id: 'description',
                                        value: event.target.value
                                    });
                                }}
                                placeholder={t(
                                    'componentsPage.customEmbeds.descInBannerPlaceholder'
                                )}
                            />
                        </Box>
                        <Box width="100%">
                            <Text fontWeight={600}>
                                {t(
                                    'componentsPage.customEmbeds.mappingUnknownCalls'
                                )}
                            </Text>
                            <MultiSelect
                                width="100%"
                                onChange={handleOnMappingChange}
                                value={formState.selectedUrls}
                                onClose={() =>
                                    setTimeout(() => setSearch(''), 100)
                                }
                            >
                                <MultiSelectButton>
                                    <Text
                                        display="flex"
                                        gap="8px"
                                        alignItems="center"
                                    >
                                        {formState.selectedUrls.length}{' '}
                                        {t('common.selected')}
                                    </Text>
                                </MultiSelectButton>
                                <MultiSelectList width="auto">
                                    <InputGroup w="auto" my="4px">
                                        <Input
                                            placeholder={t(
                                                'componentsPage.customEmbeds.mappingUnknownCallsSearchPlaceholder'
                                            )}
                                            onChange={(e: any) =>
                                                setSearch(e.target.value)
                                            }
                                            value={search}
                                        />
                                        <InputRightElement>
                                            <Icon as={MdSearch} />
                                        </InputRightElement>
                                    </InputGroup>
                                    <Divider color="#D6D6D6" />
                                    {filteredUrls.map((url) => (
                                        <MultiSelectOption
                                            key={url}
                                            value={url}
                                            type="checkmark"
                                        >
                                            {url}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectList>
                            </MultiSelect>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            background="#F2F2F2"
                            px="24px"
                            py="24px"
                            borderRadius="8px"
                        >
                            <Text fontWeight={600}>
                                {t('componentsPage.customEmbeds.showInBanner')}
                            </Text>
                            <Switch
                                size="md"
                                isChecked={formState.showInLiveBanner}
                                onChange={(event) => {
                                    updateForm(setFormState, {
                                        id: 'showInLiveBanner',
                                        value: event.target.checked
                                    });
                                }}
                            />
                        </Box>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button variant="transparent" onClick={onClose}>
                        {t('common.cancel')}
                    </Button>
                    <Button colorScheme="blue">
                        {t('common.saveChanges')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
