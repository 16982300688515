export const BoltSvg = () => (
    <svg
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect y="0.5" width="40" height="40" rx="4" fill="#ECEFFE" />
        <path
            d="M18.9961 29.5H17.9961L18.9961 22.5H15.4961C14.6161 22.5 15.1661 21.75 15.1861 21.72C16.4761 19.44 18.4161 16.04 21.0061 11.5H22.0061L21.0061 18.5H24.5161C24.9161 18.5 25.1361 18.69 24.9161 19.16C20.9661 26.05 18.9961 29.5 18.9961 29.5Z"
            fill="#1032CF"
        />
    </svg>
);
