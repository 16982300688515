/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AppInstanceState {
    Authorized = 'authorized',
    Configured = 'configured'
}

export enum BadgeStatus {
    Blocked = 'blocked',
    Red = 'red',
    Grey = 'grey'
}

export enum BannerState {
    Uninitialized = 'uninitialized',
    Unknown = 'unknown',
    Enabled = 'enabled',
    Disabled = 'disabled'
}

export enum ButtonAlignment {
    Vertical = 'vertical',
    Horizontal = 'horizontal'
}

export enum ButtonDisplayLocation {
    BottomRight = 'bottomRight',
    BottomLeft = 'bottomLeft'
}

export enum Category {
    Unknown = 'unknown',
    Necessary = 'necessary',
    Preference = 'preference',
    Statistics = 'statistics',
    Marketing = 'marketing'
}

export interface CmpConfigRequest {
    appInstanceId?: string | null;
    blocked?: boolean;
}

export enum ComplianceStatus {
    AtRisk = 'atRisk',
    LowRisk = 'lowRisk'
}

export interface Component {
    name?: string | null;
    description?: string | null;
    recommendedAction?: RecommendedAction;
    blockerCategory?: Category;
    recommendedCategory?: Category;
    blockedPriorConsent?: boolean;
    hasConflict?: boolean;
    type?: ComponentType;
    badgeStatus?: BadgeStatus;
}

export enum ComponentType {
    ThirdPartyApp = 'thirdPartyApp',
    WixStandardComponent = 'wixStandardComponent',
    WixCustomComponent = 'wixCustomComponent'
}

export interface Components {
    bundleId?: string | null;
    type?: ComponentType;
    name?: string | null;
    category?: Category;
    description?: string | null;
    templateIdentifier?: string | null;
    matchedManifest?: MatchedManifest;
    expectedUniqueRegexes?: ExpectedUniqueRegexes[] | null;
    expectedServiceRegexes?: ExpectedServiceRegexes[] | null;
}

export interface ConfigurationRequest {
    theme?: ThemeType;
    privacyButtonIsVisible?: boolean | null;
    buttonPrivacyOpenIconUrl?: string | null;
    buttonDisplayLocation?: ButtonDisplayLocation;
    secondLayer?: SecondLayer;
    firstLayer?: FirstLayer;
    customization?: Customization;
}

export interface ConfigurationResponse {
    theme?: ThemeType;
    privacyButtonIsVisible?: boolean | null;
    buttonPrivacyOpenIconUrl?: string | null;
    buttonDisplayLocation?: ButtonDisplayLocation;
    secondLayer?: SecondLayer;
    firstLayer?: FirstLayer;
    customization?: Customization;
}

export interface Customization {
    buttonAlignment?: ButtonAlignment;
    color?: CustomizationColor;
    font?: CustomizationFont;
    /** @format int32 */
    borderRadiusButton?: number | null;
    /** @format int32 */
    borderRadiusLayer?: number | null;
    logoAltTag?: string | null;
    logoUrl?: string | null;
    /** @format float */
    overlayOpacity?: number | null;
    /** @format int32 */
    privacyButtonSizeDesktop?: number | null;
    /** @format int32 */
    privacyButtonSizeMobile?: number | null;
    useBackgroundShadow?: boolean | null;
}

export interface CustomizationColor {
    acceptBtnBackground?: string | null;
    acceptBtnText?: string | null;
    denyBtnBackground?: string | null;
    denyBtnText?: string | null;
    layerBackground?: string | null;
    overlay?: string | null;
    privacyButtonBackground?: string | null;
    privacyButtonIcon?: string | null;
    saveBtnBackground?: string | null;
    saveBtnText?: string | null;
    tabsBorderColor?: string | null;
    text?: string | null;
    toggleActiveBackground?: string | null;
    toggleActiveIcon?: string | null;
    toggleDisabledBackground?: string | null;
    toggleDisabledIcon?: string | null;
    toggleInactiveBackground?: string | null;
    toggleInactiveIcon?: string | null;
    secondLayerTab?: string | null;
    linkFont?: string | null;
    moreBtnBackground?: string | null;
    moreBtnText?: string | null;
}

export interface CustomizationFont {
    family?: string | null;
    /** @format int32 */
    size?: number | null;
}

export interface Error {
    code?: string | null;
    description?: string | null;
}

export interface ErrorResponse {
    error?: Error;
}

export interface ExpectedServiceRegexes {
    service?: string | null;
    regex?: string | null;
    match?: boolean;
    category?: string | null;
    blockedPriorConsent?: boolean;
    templateIdentifier?: string | null;
}

export interface ExpectedUniqueRegexes {
    regex?: string | null;
    match?: boolean;
    blockedPriorConsent?: boolean;
    placeholderRequest?: boolean;
}

export interface FirstLayer {
    variant?: FirstLayerVariant;
    isCategoryTogglesEnabled?: boolean | null;
    hideLanguageSwitch?: boolean | null;
    hideButtonDeny?: boolean | null;
    secondLayerTrigger?: SecondLayerTrigger;
    logoPosition?: FirstLayerLogoPosition;
    isOverlayEnabled?: boolean | null;
}

export enum FirstLayerLogoPosition {
    Left = 'left',
    Center = 'center',
    Right = 'right'
}

export enum FirstLayerVariant {
    Banner = 'banner',
    Wall = 'wall'
}

export interface InputTranslations {
    af?: string | null;
    ar?: string | null;
    be?: string | null;
    bg?: string | null;
    bs?: string | null;
    ca?: string | null;
    cs?: string | null;
    cy?: string | null;
    da?: string | null;
    de?: string | null;
    el?: string | null;
    en?: string | null;
    es?: string | null;
    et?: string | null;
    fa?: string | null;
    fi?: string | null;
    fr?: string | null;
    gl?: string | null;
    he?: string | null;
    hi?: string | null;
    hr?: string | null;
    hu?: string | null;
    hy?: string | null;
    id?: string | null;
    is?: string | null;
    it?: string | null;
    ja?: string | null;
    ka?: string | null;
    kk?: string | null;
    ko?: string | null;
    lt?: string | null;
    lv?: string | null;
    mk?: string | null;
    mn?: string | null;
    ms?: string | null;
    nb?: string | null;
    nl?: string | null;
    nn?: string | null;
    no?: string | null;
    pl?: string | null;
    pt?: string | null;
    ro?: string | null;
    ru?: string | null;
    sk?: string | null;
    sl?: string | null;
    sq?: string | null;
    sr?: string | null;
    sv?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    vi?: string | null;
    zh?: string | null;
}

export interface LanguagesRequest {
    editableLanguages?: string[] | null;
    activeLanguages?: string[] | null;
}

export interface LanguagesResponse {
    editableLanguages?: string[] | null;
    activeLanguages?: string[] | null;
}

export interface LogRequest {
    level?: LogRequestLevel;
    template?: string | null;
    parameters?: string[] | null;
}

export enum LogRequestLevel {
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Critical = 'critical'
}

export enum ManifestCategory {
    Unknown = 'unknown',
    Essential = 'essential',
    Functional = 'functional',
    Analytics = 'analytics',
    Advertising = 'advertising'
}

export interface MarkNotificationsAsReadRequest {
    notificationIds?: string[] | null;
}

export interface MatchedManifest {
    name?: string | null;
    manifestCategory?: ManifestCategory;
}

export interface NotificationsResponse {
    items?: NotificationsResponseItem[] | null;
}

export interface NotificationsResponseItem {
    /** @format uuid */
    id?: string;
    type?: NotificationsResponseItemType;
    /** @format date-time */
    occuredOn?: string;
    /** @format date-time */
    readOn?: string | null;
}

export enum NotificationsResponseItemType {
    ScanCompleted = 'scanCompleted',
    ScanFailed = 'scanFailed',
    AppUpgraded = 'appUpgraded'
}

export interface PaidStatusResponse {
    paid?: boolean;
}

export interface ProblemDetails {
    type?: string | null;
    title?: string | null;
    /** @format int32 */
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
}

export interface PublicAppInstance {
    appInstanceId?: string | null;
    appInstanceState?: AppInstanceState;
    domainGroupSerialNumber?: string | null;
}

export enum RecommendedAction {
    ReportOrRemoval = 'reportOrRemoval',
    CategoryChange = 'categoryChange',
    EnablePlaceholders = 'enablePlaceholders'
}

export interface RefreshTokenResponse {
    jwt?: string | null;
}

export interface Report {
    complianceStatus?: ComplianceStatus;
    regulations?: string[] | null;
    components?: Component[] | null;
    uncategorizedComponentUrls?: string[] | null;
}

export interface ResultContent {
    otherServices?: string[] | null;
    unmatchedManifestComponents?: string[] | null;
    components?: Components[] | null;
}

export interface ScanResult {
    result?: Report;
    appInstanceId?: string | null;
    domainUrl?: string | null;
    message?: string | null;
    /** @format date-time */
    lastScanDate?: string | null;
    /** @format date-time */
    nextScanDate?: string | null;
    status?: ScanStatus;
}

export interface ScanResultResponse {
    appInstanceId?: string | null;
    /** @format date-time */
    startedTimestamp?: string;
    /** @format date-time */
    doneTimestamp?: string | null;
    status?: ScanStatus;
    domainUrl?: string | null;
    message?: string | null;
    result?: ResultContent;
}

export enum ScanStatus {
    Done = 'done',
    Pending = 'pending',
    Failed = 'failed'
}

export interface SecondLayer {
    variant?: SecondLayerVariant;
    hideLanguageSwitch?: boolean | null;
    hideButtonDeny?: boolean | null;
    isOverlayEnabled?: boolean | null;
}

export enum SecondLayerTrigger {
    Link = 'link',
    Button = 'button',
    MoreLinkButton = 'moreLinkButton'
}

export enum SecondLayerVariant {
    Side = 'side',
    Center = 'center'
}

export interface SetScriptParamsRequest {
    settingsId?: string | null;
    appInstanceId?: string | null;
    disabled?: boolean;
    /** @format int32 */
    dataCacheVersion?: number;
}

export interface SignInResponse {
    jwt?: string | null;
    appInstance?: PublicAppInstance;
}

export interface StartScanRequest {
    domainUrl?: string | null;
    /** @format uuid */
    appInstanceId?: string;
    webhookUrl?: string | null;
}

export interface StartScanResponse {
    success?: boolean;
    message?: string | null;
}

export enum ThemeType {
    Default = 'default',
    Dark = 'dark'
}

export interface TranslationDto {
    parentField?: string | null;
    childField?: string | null;
    translation?: InputTranslations;
}
