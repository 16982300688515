import { ConsentBannerFormState } from '../types/consentBanner.type';
import { PrivacyTriggerFormState } from '../types/privacyTrigger.type';

export const consentBannerInitialFormState: ConsentBannerFormState = {
    layoutType: 'wall',
    theme: 'custom',
    generalColors: {
        backgroundColor: '#FFFFFF',
        textColor: '#303030',
        linkColor: '#303030',
        tabColor: '#FFFFFF',
        accentColor: '#DDDDDD'
    },
    buttonColors: {
        acceptButtonBackground: '#1032CF',
        acceptButtonText: '#FAFAFA',
        denyButtonBackground: '#1032CF',
        denyButtonText: '#FAFAFA',
        saveButtonBackground: '#F5F5F5',
        saveButtonText: '#303030',
        moreBtnBackground: '#FFFFFF',
        moreBtnText: '#1032CF'
    },
    toggleColors: {
        activeToggleBackground: '#346BB8',
        activeToggleIcon: '#FFFFFF',
        inactiveToggleBackground: '#595959',
        inactiveToggleIcon: '#FFFFFF',
        disabledToggleBackground: '#DEDEDE',
        disabledToggleIcon: '#FFFFFF'
    },
    advancedSettings: {
        useBackgroundShadow: false,
        backgroundOverlay: false,
        overlayOpacity: '70',
        overlayColor: '#333333'
    }
};

export const privacyTriggerInitialFormState: PrivacyTriggerFormState = {
    placement: 'bottomLeft',
    icon: 'http://img.usercentrics.eu/misc/icon-fingerprint.svg',
    iconColors: {
        background: '#0C27A0',
        icon: '#FFFFFF'
    }
};
