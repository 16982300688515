import { Button, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';

type Props = {
    navigate: NavigateFunction;
};

export const NotificationNewVersion = ({ navigate }: Props) => {
    const { t } = useTranslation();

    const handleOnClick = () => {
        navigate('/banner/appearance');
    };

    return (
        <>
            <Text fontSize="15px" fontWeight={600}>
                {t('notifications.newVersion.title')}
            </Text>

            <Text fontSize="15px" fontWeight={400}>
                {t('notifications.newVersion.desc')}
            </Text>
            <Button variant="transparent" px="0" onClick={handleOnClick}>
                {t('notifications.newVersion.actionBtnText')}
            </Button>
        </>
    );
};
