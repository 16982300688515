import { Fade } from '@chakra-ui/react';
import { Navigation } from '@components/Navigation';
import { NavigationWrapper } from '@components/NavigationWrapper';
import { Box, Container } from '@usercentrics/cookiebot-ui-react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useAdmin, usePageViews, useRoot } from '../hooks';

export interface RootProps {}

export const Root: FC<RootProps> = () => {
    useAdmin();
    usePageViews();
    const { showContent } = useRoot();

    return (
        <Container pt={10} className="mainContainer">
            <Box display="flex" pb={10} gap="48px">
                <Box>
                    <NavigationWrapper>
                        <Navigation />
                    </NavigationWrapper>
                </Box>
                <Box w="full">
                    <Fade in={showContent}>
                        <Outlet />
                    </Fade>
                </Box>
            </Box>
        </Container>
    );
};
