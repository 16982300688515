import { Divider } from '@chakra-ui/react';
import {
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdSearch,
    MultiSelect,
    MultiSelectButton,
    MultiSelectList,
    MultiSelectOption,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';

import { LanguageSettingsFormState } from '../../../pages/Content/types/Content.types';
import { FormUpdate } from '../../../types';
import styles from './AddLanguage.module.css';

type Props = {
    addedLanguages: LanguageSettingsFormState['addedLanguages'];
    supportedLanguages: { label: string; value: string }[];
} & FormUpdate;

export const AddLanguage = ({
    addedLanguages,
    updateForm,
    supportedLanguages
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.languageSettings'
    });
    const mapLanguageValues = addedLanguages.map((language) => language.value);
    const [search, setSearch] = useState('');

    const filteredLanguagesList = useMemo(
        () =>
            supportedLanguages.filter(
                (language) =>
                    language.label
                        .toLowerCase()
                        .includes(search.toLowerCase()) &&
                    !mapLanguageValues.includes(language.value)
            ),
        [supportedLanguages, search, mapLanguageValues]
    );

    const handleOnChange = (event: any) => {
        setSearch('');
        const selectedValues: string[] = event.target.value;
        const selectedLanguages = supportedLanguages.filter((language) =>
            selectedValues.includes(language.value)
        );
        const newLanguages = selectedLanguages
            .filter((language) => !mapLanguageValues.includes(language.value))
            .map((language) => ({
                ...language,
                active: false
            }));
        const updatedLanguages = [...addedLanguages, ...newLanguages];
        updateForm({
            id: 'addedLanguages',
            value: updatedLanguages
        });
    };

    return (
        <MultiSelect
            onChange={handleOnChange}
            value={mapLanguageValues}
            onClose={() => setTimeout(() => setSearch(''), 100)}
        >
            <MultiSelectButton className={styles.selectButton}>
                <Text display="flex" gap="8px" alignItems="center">
                    <FaPlus /> {t('addLanguage')}
                </Text>
            </MultiSelectButton>
            <MultiSelectList>
                <InputGroup w="auto" my="4px">
                    <Input
                        placeholder={t('searchForLanguage')}
                        onChange={(e: any) => setSearch(e.target.value)}
                        value={search}
                    />
                    <InputRightElement>
                        <Icon as={MdSearch} />
                    </InputRightElement>
                </InputGroup>
                <Divider color="#D6D6D6" />
                {filteredLanguagesList.map((language) => (
                    <MultiSelectOption
                        key={language.value}
                        value={language.value}
                        type="checkmark"
                    >
                        {language.label}
                    </MultiSelectOption>
                ))}
            </MultiSelectList>
        </MultiSelect>
    );
};
