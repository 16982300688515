import './i18n';
import './index.css';

import ErrorBoundary from '@components/ErrorBoundary';
import { QueryClientProvider } from '@tanstack/react-query';
import { ChakraProvider } from '@usercentrics/cookiebot-ui-react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { queryClient } from './reactQuerySetup';
import { theme } from './theme';
import { initAmplitude } from './util/initAmplitude';
import { initSentry } from './util/initSentry';

initAmplitude();
initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </QueryClientProvider>
    </ChakraProvider>
);
