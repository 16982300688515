import { Text } from '@usercentrics/cookiebot-ui-react';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export const InputLabel = ({ children, ...rest }: Props) => (
    <Text fontSize={14} fontWeight={600} {...rest}>
        {children}
    </Text>
);
