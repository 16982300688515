import { Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';

type Props = {
    title: string;
    paragraph1: string;
    paragraph2?: string;
};

export const PageDescriptionSection = ({
    title,
    paragraph1,
    paragraph2
}: Props) => (
    <Box backgroundColor="#F2F2F2" borderRadius="16px" px="40px" py="40px">
        <Stack spacing="2">
            <Text fontSize="28px" fontWeight="700">
                {title}
            </Text>
            <Box>
                <Text fontSize="15px" color="#141414">
                    {paragraph1}
                </Text>
                <Text fontSize="15px" color="#141414">
                    {paragraph2}
                </Text>
            </Box>
        </Stack>
    </Box>
);
