import {
    MutationOptions,
    useMutation,
    UseMutationResult
} from '@tanstack/react-query';

import { sendLog } from '../api';
import { LogRequest } from '../generated-types';

type SendLogResponse = string;

export const useSendLogAPI = (
    mutationOptions: MutationOptions<SendLogResponse, unknown, LogRequest>
): UseMutationResult<SendLogResponse, unknown, LogRequest> =>
    useMutation<SendLogResponse, unknown, LogRequest, unknown>({
        mutationFn: sendLog,
        ...mutationOptions
    });
