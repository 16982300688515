import * as amplitude from '@amplitude/analytics-browser';
import { BaseEvent, EventOptions } from '@amplitude/analytics-types';
import { useCallback } from 'react';

import { useAuthStore, useClientSettingsStore } from '../stores';

export const useAmplitude = () => {
    const { clientSettingsId } = useClientSettingsStore();
    const { appInstanceId, domain } = useAuthStore();
    const sendEvent = useCallback(
        (
            eventInput: string | BaseEvent,
            eventProperties?: Record<string, any> | undefined,
            eventOptions?: EventOptions | undefined
        ) => {
            const baseProperties = {
                appInstanceId,
                clientSettingsId,
                domain
            };
            amplitude.track(
                eventInput,
                { ...baseProperties, ...eventProperties },
                eventOptions
            );
        },
        [appInstanceId, clientSettingsId, domain]
    );

    return {
        sendEvent
    };
};
