import { BannerBottom } from '@assets/BannerBottom';
import { BannerCenter } from '@assets/BannerCenter';
import {
    Box,
    Label,
    RadioCard,
    RadioCardBody,
    RadioCardGroup,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentBannerFormState } from '../../../pages/Appearance/types/consentBanner.type';

const layouts = [
    {
        label: 'appearancePage.consentBanner.layoutType.center',
        icon: BannerCenter,
        isDefault: true,
        key: 'variant',
        value: 'wall'
    },
    {
        label: 'appearancePage.consentBanner.layoutType.bottom',
        icon: BannerBottom,
        key: 'variant',
        value: 'banner',
        disabled: false
    }
];

type Props = {
    layoutType: ConsentBannerFormState['layoutType'];
    setConsentBannerFormState: Dispatch<SetStateAction<ConsentBannerFormState>>;
};

export const LayoutType = ({
    layoutType,
    setConsentBannerFormState
}: Props) => {
    const { t } = useTranslation();

    const handleOnChange = (val: string) => {
        setConsentBannerFormState((prevState: ConsentBannerFormState) => ({
            ...prevState,
            layoutType: val as ConsentBannerFormState['layoutType']
        }));
    };

    return (
        <Box>
            <Stack spacing="14px">
                <Stack spacing="4px">
                    <Text fontSize="21" fontWeight="600">
                        {t('appearancePage.consentBanner.layoutTypeTitle')}
                    </Text>
                    <Text>
                        {t('appearancePage.consentBanner.layoutTypeDesc')}
                    </Text>
                </Stack>
                <RadioCardGroup
                    value={layoutType}
                    onChange={handleOnChange}
                    spacing={4}
                >
                    <Stack direction="row" spacing={2}>
                        {layouts.map((layout, index) => {
                            const {
                                icon: IconCmp,
                                label,
                                value,
                                disabled
                            } = layout;

                            return (
                                <Label
                                    textAlign="center"
                                    key={value}
                                    width="220px"
                                    color={disabled ? 'grey' : 'black'}
                                >
                                    <RadioCard
                                        value={value}
                                        isDisabled={disabled}
                                    >
                                        <RadioCardBody
                                            display="flex"
                                            justifyContent="center"
                                            alignItems={
                                                index === 0 ? 'center' : 'end'
                                            }
                                        >
                                            <IconCmp />
                                        </RadioCardBody>
                                    </RadioCard>
                                    {t(label)}
                                </Label>
                            );
                        })}
                    </Stack>
                </RadioCardGroup>
            </Stack>
        </Box>
    );
};
