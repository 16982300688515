import {
    Select,
    SelectButton,
    SelectList,
    SelectOption
} from '@usercentrics/cookiebot-ui-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdLanguage } from 'react-icons/md';

import { useAmplitude } from '../../hooks';
import { useAuthStore } from '../../stores';

const languagesList = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Deutsch',
        value: 'de'
    },
    {
        label: 'Español',
        value: 'es'
    },
    {
        label: 'Français',
        value: 'fr'
    },
    {
        label: 'Italiano',
        value: 'it'
    },
    {
        label: 'Português',
        value: 'pt'
    }
];

export const LanguageSelect = () => {
    const { i18n } = useTranslation();
    const { appInstanceId } = useAuthStore();
    const { sendEvent } = useAmplitude();

    useEffect(() => {
        if (appInstanceId) {
            const lang = localStorage.getItem(`lang-${appInstanceId}`);

            if (!lang) {
                localStorage.setItem(`lang-${appInstanceId}`, 'en');

                return;
            }

            (async () => {
                await i18n.changeLanguage(lang);
            })();
        }
    }, [appInstanceId, i18n]);

    const handleOnLanguageChange = async (e: any) => {
        sendEvent('Change UI Language', {
            language: e.target.value
        });
        localStorage.setItem(`lang-${appInstanceId}`, e.target.value);
        await i18n.changeLanguage(e.target.value);
    };

    return (
        <Select
            value={localStorage.getItem(`lang-${appInstanceId}`) ?? 'en'}
            size="sm"
            variant="ghost"
            onChange={handleOnLanguageChange}
        >
            <SelectButton leftIcon={MdLanguage}>Select Language</SelectButton>
            <SelectList>
                {languagesList.map((language) => (
                    <SelectOption value={language.value} key={language.value}>
                        {language.label}
                    </SelectOption>
                ))}
            </SelectList>
        </Select>
    );
};
