import {
    Box,
    Label,
    RadioCard,
    RadioCardGroup,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { PrivacyTriggerFormState } from '../../../pages/Appearance/types/privacyTrigger.type';
import { FormUpdate } from '../../../types';
import { FingerprintSvg } from '../../Icons/FingerprintSvg';
import { SecuritySvg } from '../../Icons/SecuritySvg';
import { SettingsSvg } from '../../Icons/SettingsSvg';

const iconsOptions = [
    {
        label: 'fingerprint',
        icon: FingerprintSvg,
        value: 'https://img.usercentrics.eu/misc/icon-fingerprint.svg'
    },
    {
        label: 'settings',
        icon: SettingsSvg,
        value: 'https://img.usercentrics.eu/misc/icon-settings.svg'
    },
    {
        label: 'security',
        icon: SecuritySvg,
        value: 'https://img.usercentrics.eu/misc/icon-security.svg'
    }
];

type Props = FormUpdate & {
    icon: PrivacyTriggerFormState['icon'];
    background: PrivacyTriggerFormState['iconColors']['background'];
    iconColor: PrivacyTriggerFormState['iconColors']['icon'];
};

export const PrivacyTriggerIcon = ({
    updateForm,
    icon,
    background,
    iconColor
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.privacyTrigger.icon'
    });

    return (
        <Box>
            <Stack spacing="14px">
                <Stack spacing="4px">
                    <Text fontSize="21" fontWeight="600">
                        {t('title')}
                    </Text>
                    <Text>{t('desc')}</Text>
                </Stack>
                <RadioCardGroup
                    onChange={(value: string) =>
                        updateForm({
                            id: 'icon',
                            value
                        })
                    }
                    value={icon}
                    spacing={4}
                >
                    <Stack direction="row" spacing={2} width="100%">
                        {iconsOptions.map((iconOption) => {
                            const { icon: IconCmp, value, label } = iconOption;

                            return (
                                <Label textAlign="center" w="140px" key={value}>
                                    <RadioCard value={value}>
                                        <IconCmp
                                            fill={background}
                                            iconColor={iconColor}
                                            width="48px"
                                            height="48px"
                                        />
                                    </RadioCard>
                                    {t(label)}
                                </Label>
                            );
                        })}
                    </Stack>
                </RadioCardGroup>
            </Stack>
        </Box>
    );
};
