import { useToast } from '@chakra-ui/react';
import { InputLabel } from '@components/Form/InputLabel';
import {
    Box,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack
} from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdminSignIn } from '../../api/hooks/useAdminSignIn';
import { adminStoreActions, useAdminStore } from '../../stores';

const errorMessages: Record<number, string> = {
    1: 'Incorrect password',
    2: 'Second strike, 3 and you are out :)',
    3: 'Bye!'
};

export const AdminPasswordModal = () => {
    const { t } = useTranslation();
    const { showAdminModal } = useAdminStore();
    const toast = useToast();

    const [password, setPassword] = useState('');
    const [wrongPasswordCount, setWrongPasswordCount] = useState(1);
    const [showErrorGif, setShowErrorGif] = useState(false);

    const { mutate: adminSignIn, isLoading } = useAdminSignIn({
        onSuccess: () => {
            adminStoreActions.setIsAdmin(true);
            toast({
                title: 'Logged in as admin successfully',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
            adminStoreActions.setShowAdminModal(false);
        },
        onError: () => {
            const errorMessage = errorMessages[wrongPasswordCount];
            adminStoreActions.setIsAdmin(false);
            toast({
                title: errorMessage,
                status: 'error',
                duration: 3000,
                isClosable: true
            });
            setWrongPasswordCount((prevState) => prevState + 1);
        }
    });

    const onClose = () => {
        adminStoreActions.setShowAdminModal(false);
        setPassword('');
    };

    const handleAdminSignIn = () => {
        adminSignIn(password);
    };

    useEffect(() => {
        if (wrongPasswordCount >= 4) {
            setShowErrorGif(true);
            setTimeout(() => {
                adminStoreActions.setShowAdminModal(false);
                setPassword('');
                setShowErrorGif(false);
                setWrongPasswordCount(1);
            }, 5000);
        }
    }, [wrongPasswordCount]);

    return (
        <Modal
            isOpen={showAdminModal}
            onClose={onClose}
            id="upgrade-premium-modal"
        >
            <ModalOverlay />
            <ModalContent px="32px" pt="24px" pb="40px">
                <ModalHeader>
                    <Box display="flex" alignItems="center" gap="8px" mt="8px">
                        {t('adminModal.title')}
                    </Box>
                    <ModalCloseButton isDisabled={wrongPasswordCount >= 4} />
                </ModalHeader>
                <ModalBody>
                    {showErrorGif ? (
                        <Box
                            style={{
                                position: 'absolute',
                                zIndex: '9999',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            <img
                                src="https://media1.tenor.com/m/9hcOgpxfjTcAAAAC/umpire-youre-out.gif"
                                alt=""
                            />
                        </Box>
                    ) : null}
                    <Stack
                        spacing="24px"
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        px="24px"
                        py="24px"
                    >
                        <InputLabel>{t('common.password')}</InputLabel>
                        <Input
                            value={password}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                setPassword(event.target.value);
                            }}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={isLoading}
                        onClick={handleAdminSignIn}
                        isDisabled={wrongPasswordCount >= 4}
                    >
                        {t('common.login')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
