import { useEffect } from 'react';

import { appConfigStoreActions } from '../stores';

export const useScreenResolution = () => {
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1300px)');
        appConfigStoreActions.setIsScreenSmall(mediaQuery.matches);

        const handler = (event: MediaQueryListEvent) => {
            appConfigStoreActions.setIsScreenSmall(event.matches);
        };

        mediaQuery.addEventListener('change', handler);

        return () => mediaQuery.removeEventListener('change', handler);
    }, []);
};
