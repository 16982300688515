import { useCallback, useState } from 'react';

import { UpdateFormInput } from '../types';
import { useFormState } from './useFormState';

export const useForm = (defaultFormState: any) => {
    const [formState, setFormState] = useState(defaultFormState);
    const { updateForm } = useFormState();

    const reset = useCallback(() => {
        setFormState(defaultFormState);
    }, [defaultFormState]);

    return {
        update: (payload: UpdateFormInput) => updateForm(setFormState, payload),
        formState,
        reset
    };
};
