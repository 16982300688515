export const BannerBottom = () => (
    <svg
        width="211"
        height="64"
        viewBox="0 0 211 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_5624_2696)">
            <rect x="16" y="15" width="179" height="32" rx="4" fill="#1032CF" />
        </g>
        <defs>
            <filter
                id="filter0_d_5624_2696"
                x="0"
                y="0"
                width="211"
                height="64"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_5624_2696"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_5624_2696"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
);
