import defaultClasses from '@components/ComponentsCategory/ComponentsCategory.module.css';
import { Pagination } from '@components/Pagination/Pagination';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    Grid,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdErrorOutline,
    MdSearch,
    Stack,
    Tag,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BadgeStatus } from '../../api/generated-types';
import { usePagination } from '../../hooks';
import { useReportStore } from '../../stores';
import { Component } from '../../types';
import {
    Colors,
    COMPONENTS_LIMIT,
    ComponentTypeEnum,
    recommendedActionsMap
} from '../../util/constants';
import { SortIcon } from './SortIcon';

type SortState = {
    column: keyof Component | null;
    direction: 'asc' | 'desc' | 'default';
};

enum ComponentState {
    BLOCKED = 'BLOCKED',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    NONE = ''
}

const stateTranslationMap: Record<ComponentState, string> = {
    [ComponentState.BLOCKED]: 'reportPage.blocked',
    [ComponentState.ACTION_REQUIRED]: 'common.actionRequired',
    [ComponentState.NONE]: ''
};

const stateColorMap: Record<ComponentState, string> = {
    [ComponentState.BLOCKED]: Colors.Success,
    [ComponentState.ACTION_REQUIRED]: Colors.Warning,
    [ComponentState.NONE]: ''
};

const badgeToStateMap: Record<BadgeStatus, ComponentState> = {
    [BadgeStatus.Blocked]: ComponentState.BLOCKED,
    [BadgeStatus.Red]: ComponentState.ACTION_REQUIRED,
    [BadgeStatus.Grey]: ComponentState.NONE
};

const gridTemplateColumns = '2fr 1fr 1fr 1fr 30px';

const columns = [
    { name: 'name', label: 'reportPage.columnName' },
    { name: 'type', label: 'reportPage.columnType' },
    {
        name: 'recommendedCategory',
        label: 'reportPage.columnRecommendedCategory'
    },
    { name: 'state', label: 'reportPage.columnState' }
];

export const ReportIdentifiedComponents = () => {
    const { t } = useTranslation();
    const { identifiedComponents: rawIdentifiedComponents } = useReportStore();
    const [search, setSearch] = useState('');
    const [sortState, setSortState] = useState<SortState>({
        column: 'state',
        direction: 'desc'
    });

    const identifiedComponents = useMemo(
        () =>
            rawIdentifiedComponents.map((component) => ({
                ...component,
                state: badgeToStateMap[component.badgeStatus as BadgeStatus]
            })),
        [rawIdentifiedComponents]
    );

    const filteredAndSortedComponents = useMemo(() => {
        let components = identifiedComponents.filter((component) =>
            component.name.toLowerCase().includes(search.toLowerCase())
        );

        if (sortState.column && sortState.direction !== 'default') {
            const sortBoolean = (
                aValue: boolean,
                bValue: boolean,
                direction: 'asc' | 'desc'
            ) => {
                if (aValue === bValue) {
                    return 0;
                }

                if (direction === 'asc') {
                    return aValue ? -1 : 1;
                }

                return aValue ? 1 : -1;
            };

            const sortNonBoolean = (
                aValue: any,
                bValue: any,
                direction: 'asc' | 'desc'
            ) => {
                if (aValue < bValue) {
                    return direction === 'asc' ? -1 : 1;
                }

                if (aValue > bValue) {
                    return direction === 'asc' ? 1 : -1;
                }

                return 0;
            };

            components = [...components].sort((a, b) => {
                const aValue = a[sortState.column!];
                const bValue = b[sortState.column!];

                const isBoolean =
                    typeof aValue === 'boolean' && typeof bValue === 'boolean';

                if (sortState.direction !== 'default') {
                    if (isBoolean) {
                        return sortBoolean(aValue, bValue, sortState.direction);
                    }

                    return sortNonBoolean(aValue, bValue, sortState.direction);
                }

                return 0;
            });
        }

        return components;
    }, [identifiedComponents, search, sortState]);

    const {
        data,
        hasNext,
        hasPrevious,
        next,
        previous,
        last,
        first,
        from,
        to
    } = usePagination({
        items: filteredAndSortedComponents,
        limit: COMPONENTS_LIMIT
    });

    const toggleSort = (column: keyof Component) => {
        setSortState((prevState) => {
            let newDirection: 'asc' | 'desc' | 'default' = 'asc';

            if (prevState.column === column) {
                if (prevState.direction === 'asc') {
                    newDirection = 'desc';
                } else if (prevState.direction === 'desc') {
                    newDirection = 'default';
                }
            }

            return { column, direction: newDirection };
        });
    };

    if (!identifiedComponents?.length) {
        return null;
    }

    return (
        <Stack spacing="16px">
            <Box
                display="flex"
                alignItems="center"
                gap="18px"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                <Box display="flex" alignItems="center" gap="18px">
                    <Text fontSize="28px" fontWeight="700">
                        {t('reportPage.identifiedComponents')}
                    </Text>
                    <Box
                        as="span"
                        borderRadius={50}
                        py={1}
                        px={3}
                        bg="#EBEBEB"
                        fontSize="13px"
                        fontWeight="700"
                    >
                        {identifiedComponents?.length}
                    </Box>
                </Box>
                <Box display="flex" justifySelf="end" gap="10px">
                    <InputGroup w="auto">
                        <Input
                            placeholder={t('common.searchForComponents')}
                            onChange={(e: any) => setSearch(e.target.value)}
                            value={search}
                        />
                        <InputRightElement>
                            <Icon as={MdSearch} />
                        </InputRightElement>
                    </InputGroup>
                    <Link to="/banner/components">
                        <Button>
                            {t('reportPage.viewDeclaredComponents')}
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Box bg="#FDECCF" gap="40px" p="24px" borderRadius="8px">
                <Stack spacing="16px">
                    <Text>{t('reportPage.recommendation')}</Text>
                </Stack>
            </Box>
            <Box>
                <Grid
                    px={6}
                    py={2.5}
                    gridTemplateColumns={gridTemplateColumns}
                    fontSize="13px"
                    color="#666"
                    gap="8px"
                >
                    {columns.map((column) => (
                        <Box
                            key={column.name}
                            _hover={{ cursor: 'pointer' }}
                            onClick={() =>
                                toggleSort(column.name as keyof Component)
                            }
                            display="flex"
                            alignItems="center"
                            gap="4px"
                        >
                            {t(column.label)}

                            <SortIcon
                                column={column.name}
                                sortState={sortState}
                            />
                        </Box>
                    ))}
                </Grid>
                <Stack spacing="8px">
                    {data.map((component) => (
                        <Accordion
                            key={component.name}
                            allowToggle
                            reduceMotion
                            boxShadow="0 4px 6px 1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
                            border="1px solid #E2E8F0"
                            borderRadius="8px"
                            overflowWrap="anywhere"
                        >
                            <Box bg="transparent">
                                <AccordionItem bg="transparent" _hover={{}}>
                                    <AccordionButton
                                        display="grid"
                                        gridTemplateColumns={
                                            gridTemplateColumns
                                        }
                                        bg="transparent"
                                        gap="8px"
                                        px="24px"
                                        py="16px"
                                        _hover={{
                                            color: 'black'
                                        }}
                                        _expanded={{
                                            color: 'black'
                                        }}
                                    >
                                        <Box fontWeight="700" textAlign="left">
                                            {component.name}
                                        </Box>
                                        <Box textAlign="left" fontWeight={500}>
                                            {t(
                                                (ComponentTypeEnum as any)[
                                                    component.type
                                                ]
                                            )}
                                        </Box>
                                        <Box
                                            className={
                                                defaultClasses.capitalize
                                            }
                                            textAlign="left"
                                            fontWeight={500}
                                        >
                                            {component.recommendedCategory}
                                        </Box>
                                        <Box textAlign="left">
                                            <Tag
                                                background={
                                                    stateColorMap[
                                                        component.state
                                                    ]
                                                }
                                            >
                                                {t(
                                                    stateTranslationMap[
                                                        component.state
                                                    ]
                                                )}
                                            </Tag>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Flex>
                                                <Box p={0}>
                                                    <AccordionIcon />
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </AccordionButton>

                                    <AccordionPanel
                                        borderTopWidth={1}
                                        borderColor="gray.100"
                                        m={0}
                                        p={0}
                                    >
                                        <Box px="24px" py="16px">
                                            <Stack spacing="16px">
                                                {!component.blockedPriorConsent &&
                                                component.hasConflict ? (
                                                    <Box
                                                        bg="#FDECCF"
                                                        py="16px"
                                                        px="24px"
                                                        borderRadius="8px"
                                                        display="flex"
                                                        gap="8px"
                                                        alignItems="center"
                                                    >
                                                        <MdErrorOutline
                                                            fontSize={24}
                                                            color="#F6A724"
                                                        />
                                                        <Box
                                                            display="flex"
                                                            gap="4px"
                                                            alignItems="center"
                                                        >
                                                            <Text>
                                                                {t(
                                                                    'reportPage.weRecommendYouTo'
                                                                )}
                                                                :
                                                            </Text>
                                                            <Text
                                                                color="#1032CF"
                                                                fontWeight={700}
                                                            >
                                                                <Trans
                                                                    i18nKey={
                                                                        recommendedActionsMap[
                                                                            component
                                                                                .recommendedAction
                                                                        ]
                                                                    }
                                                                    components={{
                                                                        underlineLink:
                                                                            (
                                                                                <Link
                                                                                    to="/banner/blocking"
                                                                                    style={{
                                                                                        textDecoration:
                                                                                            'underline',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                />
                                                                            )
                                                                    }}
                                                                />
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                ) : null}
                                                <Box>
                                                    {component.description}
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Box>
                        </Accordion>
                    ))}
                </Stack>
            </Box>
            <Pagination
                from={from}
                to={to}
                items={filteredAndSortedComponents}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                first={first}
                previous={previous}
                next={next}
                last={last}
            />
        </Stack>
    );
};
