import { NavItem, NavItemProps } from '@usercentrics/cookiebot-ui-react';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { useAmplitude } from '../../hooks';
import { StatusEnum } from '../../util/constants';

export interface NavLinkProps extends NavItemProps {
    href: string;
    label: string;
    setHighlightParent: (value: string | null) => void;
    status?: string;
    icon?: ReactElement;
    disabled?: boolean;
    redirect?: string;
    nested?: boolean;
    parent?: string;
    highlight?: boolean;
    isExternal?: boolean;
    shouldSendEvent?: {
        eventName: string;
    };
    isClickable?: boolean;
    isCustomOnClick?: boolean;
    shouldHighlight?: boolean;
}

export const NavLink: FC<NavLinkProps> = ({
    href,
    icon,
    label,
    redirect,
    nested,
    setHighlightParent,
    parent,
    highlight,
    status,
    isExternal,
    shouldSendEvent,
    isClickable = true,
    isCustomOnClick,
    shouldHighlight = true,
    ...restProps
}) => {
    const { sendEvent } = useAmplitude();

    const { disabled } = restProps;
    const navigate = useNavigate();
    const matchPath = useMatch(href);
    const isActive = highlight || matchPath?.pathname === href;

    const handleClick = (e: any) => {
        if (shouldSendEvent) {
            sendEvent(shouldSendEvent.eventName);
        }

        if (disabled || isExternal || !isClickable || isCustomOnClick) {
            return;
        }

        if (redirect) {
            if (status !== StatusEnum.Done) {
                return;
            }
            navigate(redirect);

            return;
        }
        e.preventDefault();
        navigate(href);
    };

    useEffect(() => {
        if (isActive && parent) {
            setHighlightParent(parent);
        } else if (!nested) {
            setHighlightParent(null);
        }
    }, [isActive, nested, parent, setHighlightParent]);

    const nestedActiveStyle = useMemo(() => {
        if (isActive && nested) {
            return {
                background: 'transparent'
            };
        }

        return {};
    }, [isActive, nested]);

    return (
        <NavItem
            target={isExternal ? '_blank' : undefined}
            href={isExternal ? href : undefined}
            onClick={handleClick}
            isActive={isActive}
            leftIcon={icon}
            style={nestedActiveStyle}
            _hover={
                !shouldHighlight
                    ? {
                          backgroundColor: 'transparent!important',
                          color: 'black!important'
                      }
                    : {}
            }
            {...restProps}
        >
            {label}
        </NavItem>
    );
};
