import { NavLink } from '@components/NavLink';
import { MdHelp, Stack } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { appConfigStoreActions } from '../../stores';
import { Links } from '../../util/constants';

type Props = {
    setHighlightParent: (value: string | null) => void;
    status: string;
};

export const SupportSection = ({ setHighlightParent, status }: Props) => {
    const { t } = useTranslation();

    const handleOpenFeedbackModal = () => {
        appConfigStoreActions.setIsFeedbackModalOpen(true);
    };

    return (
        <>
            <NavLink
                href="/support"
                label={t('common.support')}
                icon={<MdHelp />}
                status={status}
                setHighlightParent={setHighlightParent}
                parent="support"
                isClickable={false}
                shouldHighlight={false}
            />
            <Stack ml="44px" spacing="4px">
                <NavLink
                    isExternal
                    href={Links.FAQ}
                    label={t('common.faqs')}
                    setHighlightParent={setHighlightParent}
                    shouldSendEvent={{
                        eventName: 'FAQs - navigation'
                    }}
                />
                <NavLink
                    isExternal
                    href={Links.OpenTicket}
                    label={t('common.openTicket')}
                    setHighlightParent={setHighlightParent}
                    shouldSendEvent={{
                        eventName: 'Open a ticket - navigation'
                    }}
                />
                <NavLink
                    isCustomOnClick
                    href=""
                    onClick={handleOpenFeedbackModal}
                    label={t('common.giveFeedback')}
                    setHighlightParent={setHighlightParent}
                    shouldSendEvent={{
                        eventName: 'Give feedback - navigation'
                    }}
                />
                <NavLink
                    isExternal
                    href={Links.GetHelp}
                    label={t('common.helpCenter')}
                    setHighlightParent={setHighlightParent}
                    shouldSendEvent={{
                        eventName: 'Get help - navigation'
                    }}
                />
            </Stack>
        </>
    );
};
