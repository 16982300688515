import {
    MutationOptions,
    useMutation,
    UseMutationResult
} from '@tanstack/react-query';

import { adminSignIn } from '../api';

export const useAdminSignIn = (
    mutationOptions: MutationOptions<string, unknown, string> = {}
): UseMutationResult<string, unknown, string> =>
    useMutation<string, unknown, string, unknown>({
        mutationFn: adminSignIn,
        ...mutationOptions
    });
