import { ConsentBannerTab } from '@components/ConsentBannerTab/ConsentBannerTab';
import { Loader } from '@components/Loader';
import { PrivacyTriggerTab } from '@components/PrivacyTriggerTab/PrivacyTriggerTab';
import {
    Box,
    Button,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppearance } from '../../hooks';
import styles from './Appearance.module.css';

export const Appearance = () => {
    const {
        loading,
        isSaveButtonDisabled,
        isSetConfigLoading,
        handleOnSubmit,
        handleOnReset,
        consentBannerFormState,
        setConsentBannerFormState,
        updateConsentBannerForm,
        updatePrivacyTriggerForm,
        privacyTriggerFormState
    } = useAppearance();

    const { t } = useTranslation();

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabsChange = (index: number) => {
        setTabIndex(index);
    };

    return (
        <>
            <Text fontSize="60px" fontWeight="800">
                {t('appearancePage.title')}
            </Text>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
                <TabList
                    className={styles.tabList}
                    py={4}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex">
                        <Tab>{t('appearancePage.consentBanner.title')}</Tab>
                        <Tab>{t('appearancePage.privacyTrigger.title')}</Tab>
                    </Box>
                    <Box
                        display="flex"
                        gap={4}
                        visibility={tabIndex !== 2 ? 'visible' : 'hidden'}
                    >
                        <Button
                            width="clamp(60px, 10vw, 132px)"
                            variant="outline"
                            onClick={handleOnReset}
                        >
                            {t('common.reset')}
                        </Button>
                        <Button
                            isLoading={isSetConfigLoading}
                            onClick={handleOnSubmit}
                            width="clamp(60px, 10vw, 132px)"
                            isDisabled={isSaveButtonDisabled}
                            backgroundColor="#076646"
                        >
                            {t('common.save')}
                        </Button>
                    </Box>
                </TabList>
                {!loading &&
                privacyTriggerFormState &&
                consentBannerFormState ? (
                    <TabPanels>
                        <TabPanel>
                            <ConsentBannerTab
                                consentBannerFormState={consentBannerFormState}
                                setConsentBannerFormState={
                                    setConsentBannerFormState
                                }
                                updateConsentBannerForm={
                                    updateConsentBannerForm
                                }
                            />
                        </TabPanel>
                        <TabPanel>
                            <PrivacyTriggerTab
                                updateForm={updatePrivacyTriggerForm}
                                formState={privacyTriggerFormState}
                            />
                        </TabPanel>
                    </TabPanels>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        h="100%"
                        my={10}
                    >
                        <Loader />
                    </Box>
                )}
            </Tabs>
        </>
    );
};
