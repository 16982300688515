import { proxy, useSnapshot } from 'valtio';

import { Component } from '../../types';
import { complianceKeys } from '../../util/constants';

type State = {
    reportData: any;
    status: string;
    message: string;
    isLowRisk: boolean;
    lastScanDate: string;
    complianceStatus: string;
    nextScanDate: string;
    regulations: string[];
    hasConflict: boolean;
    components: Component[];
    conflictingComponents: Component[];
    isLoading: boolean;
    error: any;
    unknownComponents: string[];
    identifiedComponents: Component[];
};

const defaultState: State = {
    reportData: null,
    status: '',
    isLoading: true,
    error: null,
    get message() {
        return this.reportData?.message;
    },
    get lastScanDate() {
        return this.reportData?.lastScanDate;
    },
    get nextScanDate() {
        return this.reportData?.nextScanDate;
    },
    get regulations() {
        return this.reportData?.result?.regulations;
    },
    get complianceStatus() {
        return this.reportData?.result?.complianceStatus;
    },
    get isLowRisk() {
        return (
            this.reportData?.result?.complianceStatus === complianceKeys.lowRisk
        );
    },
    get components(): Component[] {
        const dataComponents = this.reportData?.result?.components;
        const otherServices =
            this.reportData?.result?.uncategorizedComponentUrls;
        let otherServicesComponents: Component[] = [];

        if (otherServices?.length) {
            otherServicesComponents = otherServices.map((url: string) => ({
                name: url,
                hasConflict: true,
                type: 'otherServices'
            }));
        }

        if (dataComponents) {
            return [...dataComponents, ...otherServicesComponents];
        }

        return [];
    },
    get conflictingComponents(): Component[] {
        return this.components.filter(
            (component: any) => component.hasConflict
        );
    },
    get hasConflict() {
        return this.components.some((component: any) => component.hasConflict);
    },
    get unknownComponents() {
        return this.reportData?.result?.uncategorizedComponentUrls;
    },
    get identifiedComponents() {
        return this.reportData?.result?.components;
    }
};

const state = proxy(defaultState);

export const reportStoreActions = {
    setReportData: (reportData: any) => {
        state.reportData = reportData;
    },
    setStatus: (status: string) => {
        state.status = status;
    },
    setIsLoading: (isLoading: boolean) => {
        state.isLoading = isLoading;
    },
    setError: (error: any) => {
        state.error = error;
    }
};

export const useReportStore = () => useSnapshot<State>(state);
