export const callApi = (
    input: RequestInfo | URL,
    init?: RequestInit
): Promise<Response> =>
    new Promise((resolve, reject) => {
        fetch(input, init)
            .then((resp) => {
                if (!resp.ok) {
                    reject(resp);
                } else {
                    resolve(resp);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
