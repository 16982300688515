import { Box, Switch, Text } from '@usercentrics/cookiebot-ui-react';

import { usePlaceholdersSwitch } from '../../hooks';
import { Loader } from '../Loader';

export const PlaceholdersSwitch = () => {
    const { loading, isChecked, handleSwitchChange, t } =
        usePlaceholdersSwitch();

    return (
        <Box
            backgroundColor="#fff"
            px="14px"
            py="14px"
            borderRadius="6px"
            display="flex"
            alignItems="center"
            gap="8px"
        >
            <Text fontWeight="600">
                {t('appearancePage.placeholders.enablePlaceholders')}
            </Text>
            <Switch
                isDisabled={loading}
                isChecked={isChecked}
                onChange={handleSwitchChange}
            />
            {loading ? <Loader width="30" height="30" /> : null}
        </Box>
    );
};
