import { Box } from '@usercentrics/cookiebot-ui-react';

import { OtherServicesBanner } from '../OtherServicesBanner/OtherServicesBanner';
import { StandardComponentsSectionDetails } from '../SectionDetails/StandardComponentsSectionDetails';
import { ThirdPartyAppsSectionDetails } from '../SectionDetails/ThirdPartyAppsSectionDetails';

type Props = {
    isWixComponents: boolean;
    isThirdPartyApps: boolean;
    isOtherServices: boolean;
    isComponentsPage: boolean;
};

export const ComponentCategoryDescription = ({
    isWixComponents,
    isThirdPartyApps,
    isOtherServices,
    isComponentsPage
}: Props) => (
    <>
        {isWixComponents && isComponentsPage ? (
            <Box mb="24px">
                <StandardComponentsSectionDetails />
            </Box>
        ) : null}
        {isThirdPartyApps && isComponentsPage ? (
            <Box mb="24px">
                <ThirdPartyAppsSectionDetails />
            </Box>
        ) : null}
        {isOtherServices && isComponentsPage ? (
            <Box mb="24px">
                <ThirdPartyAppsSectionDetails />
            </Box>
        ) : null}
        {isOtherServices ? (
            <OtherServicesBanner isOtherServices={isOtherServices} />
        ) : null}
    </>
);
