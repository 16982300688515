import fetchWithAuth from '../util/fetchWithAuth';

export const callApiWithAuth = async (
    input: string,
    init?: RequestInit
): Promise<Response> => {
    try {
        const resp = await fetchWithAuth(input, init);

        if (!resp.ok) {
            throw await resp.json();
        }

        return resp;
    } catch (error) {
        if (error instanceof Response) {
            throw await error.json();
        }
        throw error;
    }
};
