import { Switch } from '@chakra-ui/react';
import { Loader } from '@components/Loader';
import {
    Box,
    Flex,
    Stack,
    Text,
    Tooltip
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useBannerSwitch } from '../../hooks';
import { useAuthStore, usePremiumStore } from '../../stores';

export const BannerScriptSwitch = () => {
    const { t } = useTranslation();
    const { isPremium } = usePremiumStore();
    const { domain } = useAuthStore();

    const { isScriptEnabled, handleSwitchChange, isLoading } =
        useBannerSwitch();

    return (
        <Box bg="rgba(0, 122, 255, 0.10)" p="16px" borderRadius="8px">
            <Stack spacing="8px">
                <Box display="flex" gap="8px" alignItems="center">
                    <Text fontSize="21px" fontWeight="600">
                        {isScriptEnabled
                            ? t('banner.bannerLive')
                            : t('banner.bannerNotLive')}
                    </Text>
                    <Box
                        width="18px"
                        height="18px"
                        backgroundColor={
                            isScriptEnabled ? '#34C759' : '#E24343'
                        }
                        borderRadius="50%"
                    />
                </Box>
                <Box fontWeight="400" color="#757575">
                    {isScriptEnabled
                        ? t('banner.bannerLiveDesc')
                        : t('banner.bannerNotLiveDesc')}
                </Box>
                <Flex alignItems="center" gap="4px" mt="8px">
                    <Switch
                        display="flex"
                        alignItems="center"
                        isChecked={isScriptEnabled}
                        onChange={handleSwitchChange}
                        disabled={!isPremium || !domain || isLoading}
                        cursor="pointer"
                    >
                        {isLoading ? (
                            <Loader width="40" height="40" />
                        ) : (
                            <Text fontWeight={600}>
                                {t('banner.enableBanner')}
                            </Text>
                        )}
                    </Switch>

                    {!domain && (
                        <Tooltip
                            label={t('common.noDomain', {
                                domain
                            })}
                            placement="bottom"
                            shouldWrapChildren
                        />
                    )}
                </Flex>
            </Stack>
        </Box>
    );
};
