import {
    AccordionButton,
    AccordionIcon,
    Box,
    Flex
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    numOfItems: number;
};

export const ComponentCategoryButton = ({ name, numOfItems }: Props) => {
    const { t } = useTranslation();

    return (
        <AccordionButton
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="black"
            _expanded={{ color: 'black' }}
            _hover={{
                color: 'black'
            }}
        >
            <Flex gap={2} alignItems="center">
                {t(name)}
                <Box
                    as="span"
                    borderRadius={50}
                    bg="white"
                    py={1}
                    px={3}
                    color="gray.500"
                >
                    {numOfItems}
                </Box>
            </Flex>
            <AccordionIcon />
        </AccordionButton>
    );
};
