import { FaqSection } from '@components/FaqSection';
import { MainBanner } from '@components/MainBanner';
import { ScanStatus } from '@components/ScanStatus';
import { Box, Flex, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useAppConfigStore } from '../stores';

export const Dashboard = () => {
    const { t } = useTranslation();
    const { appError } = useAppConfigStore();
    const { isScreenSmall } = useAppConfigStore();

    return (
        <>
            <Box display="flex" justifyContent="center" w="100%">
                <MainBanner />
            </Box>
            <Flex gap={10} flexDirection={isScreenSmall ? 'column' : 'initial'}>
                <Box w="100%">
                    <Text fontSize={21} fontWeight={600} mb="16px">
                        {t('dashboard.scanOverview.title')}
                    </Text>
                    <Box
                        bg={appError ? '#9F1818' : 'gray.50'}
                        borderRadius="lg"
                        py={6}
                        px={12}
                        w="100%"
                        alignSelf="flex-start"
                    >
                        <ScanStatus />
                    </Box>
                </Box>
                <Box w="100%">
                    <FaqSection />
                </Box>
            </Flex>
        </>
    );
};
