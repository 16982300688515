import { useQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAppInstance, getDomain } from '../api/api';
import { useSignIn } from '../api/hooks';
import {
    appConfigStoreActions,
    authStoreActions,
    useAuthStore
} from '../stores';

export const useAuth = () => {
    const { appInstanceId } = useAuthStore();
    const { t } = useTranslation();

    const [token, setToken] = useState<string>('');

    const urlParams = new URLSearchParams(document.location.search);

    const appInstanceToken = urlParams.get('instance') ?? '';

    const isInitialLoad = useRef(true);

    const { mutate: signIn, isLoading: isSignInLoading } = useSignIn({
        onSuccess: (data) => {
            const instanceId = data.appInstance?.appInstanceId;
            const jwt = data?.jwt;

            if (instanceId) {
                authStoreActions.setAppInstanceId(instanceId);
            }

            if (jwt) {
                setToken(jwt);
                localStorage.setItem(`token-${instanceId}`, jwt);
                localStorage.setItem(
                    `refreshToken-${instanceId}`,
                    appInstanceToken
                );
            }
        },
        onError: () => {
            appConfigStoreActions.setAppError({
                hasError: true,
                reason: 'authFailed'
            });
        }
    });

    const { data: domainData, isLoading: isDomainLoading } = useQuery({
        queryKey: ['getInstance', { appInstanceId }],
        queryFn: () => getDomain({ appInstanceId }),
        enabled: appInstanceId !== '' && token !== ''
    });

    const { data: appInstanceData, isLoading: isAppInstanceLoading } = useQuery(
        {
            queryKey: ['getAppInstance', { appInstanceId }],
            queryFn: () => getAppInstance({ appInstanceId }),
            enabled:
                !!appInstanceId &&
                appInstanceId !== '' &&
                appInstanceId !== undefined
        }
    );

    useEffect(() => {
        if (appInstanceToken) {
            const decoded: any = jwtDecode(appInstanceToken);
            authStoreActions.setAppId(decoded.appDefId);
        }
    }, [appInstanceToken]);

    useEffect(() => {
        if (appInstanceData) {
            authStoreActions.setSiteId(appInstanceData.site.siteId);
        }
    }, [appInstanceData]);

    useEffect(() => {
        if (domainData) {
            authStoreActions.setDomain(domainData);
        }
    }, [domainData]);

    useEffect(() => {
        if (appInstanceToken) {
            signIn(appInstanceToken);
        }
    }, [appInstanceToken, signIn]);

    useEffect(() => {
        authStoreActions.setIsLoading(
            isSignInLoading || isDomainLoading || isAppInstanceLoading
        );
    }, [isAppInstanceLoading, isDomainLoading, isSignInLoading]);

    useEffect(() => {
        if (!appInstanceToken && isInitialLoad.current) {
            appConfigStoreActions.setAppError({
                hasError: true,
                reason: 'missingToken'
            });
        }
        isInitialLoad.current = false;
    }, [appInstanceToken, t]);
};
