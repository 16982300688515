import { callApi } from './fetchUtils';
import { SignInResponse } from './generated-types';

const BaseUrl = `${import.meta.env.VITE_API_BASE_URL}`;

export const signIn = async (token: string): Promise<SignInResponse> => {
    const response = await callApi(`${BaseUrl}/Authentication/signIn`, {
        method: 'POST',
        headers: {
            appinstancetoken: token,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });

    return response.json();
};
