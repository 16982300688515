import { Divider } from '@chakra-ui/react';
import { ComponentsReport } from '@components/ComponentsReport';
import { Loader } from '@components/Loader';
import { PageDescriptionSection } from '@components/PageDescriptionSection/PageDescriptionSection';
import { Box, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useComponents } from '../hooks';

export const Components = () => {
    const { t } = useTranslation();

    const { isLoading, groupedComponents } = useComponents();

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                h="100%"
                my={10}
            >
                <Loader />
            </Box>
        );
    }

    return (
        <>
            <Text fontSize="60px" fontWeight="800">
                {t('routes.components')}
            </Text>
            <Divider />
            <Box my="56px">
                <PageDescriptionSection
                    title={t('componentsPage.title')}
                    paragraph1={t('componentsPage.desc1p')}
                    paragraph2={t('componentsPage.desc2p')}
                />
            </Box>
            <ComponentsReport
                heading={t('common.componentsFullReport')}
                groupedComponents={groupedComponents}
                isComponentsPage
            />
        </>
    );
};
