import { useTranslation } from 'react-i18next';

import { SectionDetails } from './SectionDetails';

export const StandardComponentsSectionDetails = () => {
    const { t } = useTranslation();

    return (
        <SectionDetails
            title={t('reportPage.sectionDetailsTitle')}
            paragraph1={t('reportPage.sectionDetails1p')}
            paragraph2={t('reportPage.sectionDetails2p')}
        />
    );
};
