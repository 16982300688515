import { Box, Grid, Input, Stack } from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { InputLabel } from '../../Form/InputLabel';
import { SectionTitle } from '../../Form/SectionTitle';

type Props = {
    legalNoticeLinkText: string;
    legalNoticeUrl: string;
    privacyPolicyLinkText: string;
    privacyPolicyUrl: string;
    updateForm: any;
};

export const LegalTextLinks = ({
    legalNoticeLinkText,
    legalNoticeUrl,
    privacyPolicyLinkText,
    privacyPolicyUrl,
    updateForm
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.textLabels.legalTextLinks'
    });

    return (
        <Box>
            <Stack spacing="24px">
                <SectionTitle>{t('title')}</SectionTitle>
                <Grid
                    templateRows="1fr 1fr"
                    templateColumns="1fr 1fr"
                    gap="24px"
                >
                    <Box>
                        <InputLabel>{t('legalNoticeLinkText')}</InputLabel>
                        <Input
                            value={legalNoticeLinkText}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'legalTextLinks',
                                    id: 'legalNoticeLinkText',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('legalNoticeUrl')}</InputLabel>
                        <Input
                            value={legalNoticeUrl}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'legalTextLinks',
                                    id: 'legalNoticeUrl',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('privacyPolicyLinkText')}</InputLabel>
                        <Input
                            value={privacyPolicyLinkText}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'legalTextLinks',
                                    id: 'privacyPolicyLinkText',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('privacyPolicyUrl')}</InputLabel>
                        <Input
                            value={privacyPolicyUrl}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'legalTextLinks',
                                    id: 'privacyPolicyUrl',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                </Grid>
            </Stack>
        </Box>
    );
};
