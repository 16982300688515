import { PlaceholdersSwitch } from '@components/PlaceholdersSwitch';
import { Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

export const Blocking = () => {
    const { t } = useTranslation();

    return (
        <Box backgroundColor="#F2F2F2" borderRadius="16px" px="40px" py="40px">
            <Stack spacing="32px">
                <Stack spacing="2">
                    <Text fontSize="28px" fontWeight="700">
                        {t('appearancePage.placeholders.header')}
                    </Text>
                    <Text fontSize="15px" color="#141414">
                        {t('appearancePage.placeholders.subTitle')}
                    </Text>
                </Stack>
                <PlaceholdersSwitch />
            </Stack>
        </Box>
    );
};
