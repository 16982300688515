/* eslint-disable react/destructuring-assignment */
import { Box, Button, Container, Text } from '@usercentrics/cookiebot-ui-react';
import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

interface ErrorBoundaryProps {
    children: ReactNode;
}

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, error, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo });
        console.error('ErrorBoundary caught an error', error, errorInfo);
    }

    // eslint-disable-next-line class-methods-use-this
    handleReload() {
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100vh"
                        p={4}
                    >
                        <Text fontSize="2xl" fontWeight="bold" mb={4}>
                            Oops! Something went wrong.
                        </Text>
                        <Text mb={4}>
                            An unexpected error has occurred. Please try
                            reloading the page
                        </Text>
                        <Button color="primary" onClick={this.handleReload}>
                            <Text color="white">Reload</Text>
                        </Button>
                        <Box
                            style={{
                                whiteSpace: 'pre-wrap',
                                marginTop: '20px',
                                color: 'gray.500'
                            }}
                        >
                            {this.state.error?.toString()}
                            <br />
                            {this.state.errorInfo?.componentStack}
                        </Box>
                    </Box>
                </Container>
            );
        }

        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children;
    }
}

export default ErrorBoundary;
