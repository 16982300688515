import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getNotifications, setReadNotifications } from '../../api/api';
import { useAmplitude } from '../../hooks';
import { useAuthStore } from '../../stores';

export const useNotifications = () => {
    const { appInstanceId } = useAuthStore();
    const queryClient = useQueryClient();
    const { sendEvent } = useAmplitude();

    const [showDropdown, setShowDropdown] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const { data, isLoading: notificationsLoading } = useQuery({
        queryKey: ['getNotifications', appInstanceId],
        queryFn: () => getNotifications({ appInstanceId }),
        enabled: !!appInstanceId
    });

    const { mutate: setAsRead, isLoading: isSetReadLoading } = useMutation({
        mutationFn: setReadNotifications
    });

    const notifications = useMemo(() => data?.items || [], [data?.items]);

    const hasUnreadNotifications = useMemo(
        () => notifications.some((notification) => !notification.readOn),
        [notifications]
    );

    const reFetchNotifications = useCallback(() => {
        queryClient.invalidateQueries(['getNotifications', appInstanceId]);
    }, [appInstanceId, queryClient]);

    const handleMarkAllAsRead = useCallback(() => {
        sendEvent('Read all notifications');
        const ids = notifications.reduce((acc: string[], notification) => {
            if (notification.id !== undefined && !notification.readOn) {
                acc.push(notification.id);
            }

            return acc;
        }, []);

        if (ids.length) {
            setAsRead(
                {
                    appInstanceId,
                    notificationIds: ids
                },
                {
                    onSuccess: () => {
                        reFetchNotifications();
                    }
                }
            );
        }
    }, [
        appInstanceId,
        notifications,
        reFetchNotifications,
        sendEvent,
        setAsRead
    ]);

    const handleMarkNotificationAsRead = useCallback(
        (notificationId: string) => {
            sendEvent('Read single notification');
            setAsRead(
                {
                    appInstanceId,
                    notificationIds: [notificationId]
                },
                {
                    onSuccess: () => {
                        reFetchNotifications();
                    }
                }
            );
        },
        [appInstanceId, reFetchNotifications, sendEvent, setAsRead]
    );

    const loading = notificationsLoading || isSetReadLoading;

    const handleOnNotificationClick = () => {
        sendEvent('Notification icon clicked');
        setShowDropdown((prevState) => !prevState);
    };

    return {
        notifications,
        hasUnreadNotifications,
        showDropdown,
        handleOnNotificationClick,
        dropdownRef,
        handleMarkAllAsRead,
        handleMarkNotificationAsRead,
        loading
    };
};
