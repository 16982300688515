import { NotificationNewVersion } from '@components/Notifications/NotificationNewVersion';
import { NotificationScanCompleted } from '@components/Notifications/NotificationScanCompleted';
import { NotificationScanFailed } from '@components/Notifications/NotificationScanFailed';
import { Box, Flex, Text } from '@usercentrics/cookiebot-ui-react';
import { useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';

import {
    NotificationsResponseItem,
    NotificationsResponseItemType
} from '../../api/generated-types';
import { useReportStore } from '../../stores';
import { StatusEnum } from '../../util/constants';
import { formatDate } from '../../util/generalUtils';

type Props = {
    notification: NotificationsResponseItem;
    index: number;
    handleMarkNotificationAsRead: (id: string) => void;
    navigate: NavigateFunction;
    loading: boolean;
};

const notificationComponentMap: Record<NotificationsResponseItemType, any> = {
    [NotificationsResponseItemType.ScanCompleted]: NotificationScanCompleted,
    [NotificationsResponseItemType.ScanFailed]: NotificationScanFailed,
    [NotificationsResponseItemType.AppUpgraded]: NotificationNewVersion
};

export const NotificationItem = ({
    notification,
    index,
    handleMarkNotificationAsRead,
    navigate,
    loading
}: Props) => {
    const { status } = useReportStore();

    const { type } = notification;

    const handleGoToScanResult = () => {
        if (
            status === StatusEnum.Done &&
            notification.type === 'scanCompleted' &&
            index === 0
        ) {
            navigate('/report');
        }
    };

    const NotificationTemplate = useMemo(
        () => notificationComponentMap[type as NotificationsResponseItemType],
        [type]
    );

    return (
        <Box
            _hover={{
                background: loading ? 'transparent' : '#ECEFFE',
                cursor: 'pointer'
            }}
            onClick={() =>
                !notification.readOn &&
                handleMarkNotificationAsRead(notification.id as string)
            }
            px={4}
        >
            <Flex
                alignItems={index !== 0 ? 'center' : 'start'}
                justifyContent="space-between"
                gap="8px"
                p="16px"
            >
                <Flex gap="8px">
                    {!notification.readOn ? (
                        <Box mt="7px" position="absolute" left="14px">
                            <Box
                                bg="#CB1F1F"
                                borderRadius="50%"
                                w="10px"
                                h="10px"
                            />
                        </Box>
                    ) : null}
                    <Box>
                        <NotificationTemplate
                            index={index}
                            notification={notification}
                            handleGoToScanResult={handleGoToScanResult}
                            navigate={navigate}
                        />
                    </Box>
                </Flex>
                <Text
                    fontSize="13px"
                    fontWeight={500}
                    onClick={handleGoToScanResult}
                >
                    {formatDate(notification.occuredOn as string)}
                </Text>
            </Flex>
        </Box>
    );
};
