import { BannerMenuIcon } from '@assets/BannerMenuIcon';
import { NavLink } from '@components/NavLink';
import { Stack } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { StatusEnum } from '../../util/constants';

type Props = {
    setHighlightParent: (value: string | null) => void;
    status: string;
    appError: any;
    highlightParent: string | null;
};

export const BannerSection = ({
    setHighlightParent,
    status,
    appError,
    highlightParent
}: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <NavLink
                href="/banner"
                label={t('routes.banner')}
                redirect="/banner/components"
                icon={<BannerMenuIcon />}
                highlight={highlightParent === 'banner'}
                status={status}
                setHighlightParent={setHighlightParent}
                parent="banner"
            />
            <Stack ml="44px" spacing="4px">
                <NavLink
                    href="/banner/components"
                    label={t('routes.components')}
                    disabled={status !== StatusEnum.Done}
                    setHighlightParent={setHighlightParent}
                    parent="banner"
                    nested
                />
                <NavLink
                    href="/banner/appearance"
                    label={t('routes.appearance')}
                    setHighlightParent={setHighlightParent}
                    parent="banner"
                    nested
                    disabled={!!appError}
                />
                <NavLink
                    href="/banner/content"
                    label={t('routes.content')}
                    setHighlightParent={setHighlightParent}
                    parent="banner"
                    nested
                    disabled={!!appError}
                />
                <NavLink
                    href="/banner/blocking"
                    label={t('appearancePage.placeholders.title')}
                    setHighlightParent={setHighlightParent}
                    parent="banner"
                    nested
                    disabled={!!appError}
                />
            </Stack>
        </>
    );
};
