import { Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';

type Props = {
    title: string;
    paragraph1: string;
    paragraph2?: string;
};

export const SectionDetails = ({ title, paragraph1, paragraph2 }: Props) => (
    <Stack
        background="#FFFFFF80"
        borderRadius={8}
        px="24px"
        py="24px"
        gap="8px"
    >
        <Text fontSize="17px" fontWeight={600}>
            {title}
        </Text>
        <Box>
            <Text>{paragraph1}</Text>
            <Text>{paragraph2}</Text>
        </Box>
    </Stack>
);
