import { signIn } from '../api/authService';
import { authStoreActions } from '../stores';

const fetchWithAuth = async (
    url: string,
    options: RequestInit = {}
): Promise<Response> => {
    const appInstanceId = authStoreActions.getAppInstanceId();
    const token = localStorage.getItem(`token-${appInstanceId}`);
    const refreshToken = localStorage.getItem(`refreshToken-${appInstanceId}`);

    let updatedOptions = options;

    if (token) {
        updatedOptions = {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`
            }
        };
    }

    const response = await fetch(url, updatedOptions);

    if (response.status === 401 && refreshToken) {
        const refreshedToken = await signIn(refreshToken);

        if (refreshedToken.jwt) {
            localStorage.setItem(`token-${appInstanceId}`, refreshedToken.jwt);

            return fetchWithAuth(url, options);
        }
        window.location.reload();
    }

    return response;
};

export default fetchWithAuth;
