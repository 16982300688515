import {
    Flex,
    IconButton,
    MdFirstPage,
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdLastPage,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { COMPONENTS_LIMIT } from '../../util/constants';

type Props = {
    from: number;
    to: number;
    items: readonly any[];
    hasNext: boolean;
    hasPrevious: boolean;
    first: () => void;
    previous: () => void;
    next: () => void;
    last: () => void;
};

export const Pagination = ({
    last,
    first,
    from,
    to,
    hasNext,
    hasPrevious,
    next,
    previous,
    items
}: Props) => {
    const { t } = useTranslation();

    if (items.length <= COMPONENTS_LIMIT) {
        return null;
    }

    return (
        <Flex justifyContent="end" alignItems="center">
            <Text mr={8} size="xs" fontWeight={700}>
                {t('common.from')} {from}-{to} {t('common.of')} {items.length}
            </Text>
            <Flex>
                <IconButton
                    aria-label="First page"
                    disabled={!hasPrevious}
                    color="blue.500"
                    icon={<MdFirstPage />}
                    onClick={first}
                />
                <IconButton
                    aria-label="Previous page"
                    color="blue.500"
                    disabled={!hasPrevious}
                    icon={<MdKeyboardArrowLeft />}
                    onClick={previous}
                />
                <IconButton
                    aria-label="Next page"
                    disabled={!hasNext}
                    color="blue.500"
                    icon={<MdKeyboardArrowRight />}
                    onClick={next}
                />
                <IconButton
                    aria-label="Last page"
                    disabled={!hasNext}
                    color="blue.500"
                    icon={<MdLastPage />}
                    onClick={last}
                />
            </Flex>
        </Flex>
    );
};
