import * as Sentry from '@sentry/react';

export const initSentry = () => {
    Sentry.init({
        dsn: 'https://6aaa369c591abc34ce9aae5fa85b1fc7@o4505799678623744.ingest.us.sentry.io/4506891280056320',
        integrations: [Sentry.browserTracingIntegration()],
        // Performance Monitoring
        enabled: `${import.meta.env.VITE_ENV}` !== 'local',
        environment: `${import.meta.env.VITE_ENV}`,
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled,
        tracePropagationTargets: [
            'wixapp.cookiebot.com',
            'wixapp.shared.dev.cookiebot.cloud'
        ]
    });
};
