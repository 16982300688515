import { Box, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { ScanStatusLastScan } from './ScanStatusLastScan';
import { ScanStatusNextSchedule } from './ScanStatusNextSchedule';
import { ScanStatusViewScanBtn } from './ScanStatusViewScanBtn';

type Props = {
    status: string;
};

export const ScanStatusDone = ({ status }: Props) => {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" gap="16px">
            <ScanStatusLastScan status={status} />
            <Text>
                {t('dashboard.scanOverview.scanStatusDone.description')}
            </Text>
            <ScanStatusViewScanBtn />
            <ScanStatusNextSchedule />
        </Box>
    );
};
