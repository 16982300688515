import { Box, Grid, Input, Stack } from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TextLabelsFormState } from '../../../pages/Content/types/Content.types';
import { FormUpdate } from '../../../types';
import { InputLabel } from '../../Form/InputLabel';
import { SectionTitle } from '../../Form/SectionTitle';

type Props = TextLabelsFormState['buttonLabels'] & FormUpdate;

export const ButtonLabels = ({
    buttonAccept,
    buttonMore,
    buttonSave,
    buttonDeny,
    updateForm
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.textLabels.buttonLabels'
    });

    return (
        <Box>
            <Stack spacing="24px">
                <SectionTitle>{t('title')}</SectionTitle>
                <Grid
                    templateRows="1fr 1fr"
                    templateColumns="1fr 1fr"
                    gap="24px"
                >
                    <Box>
                        <InputLabel>{t('buttonAccept')}</InputLabel>
                        <Input
                            value={buttonAccept}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'buttonLabels',
                                    id: 'buttonAccept',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('buttonDeny')}</InputLabel>
                        <Input
                            value={buttonDeny}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'buttonLabels',
                                    id: 'buttonDeny',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('buttonSave')}</InputLabel>
                        <Input
                            value={buttonSave}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'buttonLabels',
                                    id: 'buttonSave',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('buttonMore')}</InputLabel>
                        <Input
                            value={buttonMore}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateForm({
                                    formSection: 'buttonLabels',
                                    id: 'buttonMore',
                                    value: event.target.value
                                });
                            }}
                        />
                    </Box>
                </Grid>
            </Stack>
        </Box>
    );
};
