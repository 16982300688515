import { Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

export const NotificationScanFailed = () => {
    const { t } = useTranslation();

    return (
        <Text fontSize="15px" fontWeight={600}>
            {t('notifications.scanFailed')}
        </Text>
    );
};
