import { Divider } from '@chakra-ui/react';
import { Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import {
    Language,
    TextLabelsFormState
} from '../../pages/Content/types/Content.types';
import { FormUpdate } from '../../types';
import { BannerIntroMessage } from './BannerIntroMessage/BannerIntroMessage';
import { ButtonLabels } from './ButtonLabels/ButtonLabels';
import { LanguageSelection } from './LanguageSelection/LanguageSelection';
import { LegalTextLinks } from './LegalTextLinks/LegalTextLinks';

type Props = {
    formState: TextLabelsFormState;
    changeTab: (tab: number) => void;
    addedLanguages: Language[];
} & FormUpdate;

export const TextLabelsTab = ({
    formState,
    updateForm,
    changeTab,
    addedLanguages
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.textLabels'
    });

    return (
        <Box>
            <Stack spacing="40px">
                <Box
                    backgroundColor="#F2F2F2"
                    borderRadius="16px"
                    px="40px"
                    py="40px"
                >
                    <Stack spacing="24px">
                        <Box>
                            <Text fontSize="28px" fontWeight="700" mb="16px">
                                {t('title')}
                            </Text>
                            <Text fontSize="15px" color="#141414" mb="32px">
                                {t('desc')}
                            </Text>
                            <Text fontSize="13px" fontWeight={600}>
                                {t('note')}
                            </Text>
                        </Box>
                        <Divider />
                        <LanguageSelection
                            selectedLanguage={formState.selectedLanguage}
                            updateForm={updateForm}
                            changeTab={changeTab}
                            addedLanguages={addedLanguages}
                        />
                    </Stack>
                </Box>
                <BannerIntroMessage
                    title={formState.bannerIntroMessage.title}
                    message={formState.bannerIntroMessage.bannerMessage}
                    updateForm={updateForm}
                />
                <Divider />
                <LegalTextLinks
                    legalNoticeLinkText={
                        formState.legalTextLinks.legalNoticeLinkText
                    }
                    legalNoticeUrl={formState.legalTextLinks.legalNoticeUrl}
                    privacyPolicyLinkText={
                        formState.legalTextLinks.privacyPolicyLinkText
                    }
                    privacyPolicyUrl={formState.legalTextLinks.privacyPolicyUrl}
                    updateForm={updateForm}
                />
                <Divider />
                <ButtonLabels
                    buttonAccept={formState.buttonLabels.buttonAccept}
                    buttonDeny={formState.buttonLabels.buttonDeny}
                    buttonSave={formState.buttonLabels.buttonSave}
                    buttonMore={formState.buttonLabels.buttonMore}
                    updateForm={updateForm}
                />
            </Stack>
        </Box>
    );
};
