import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

type SortIconProps = {
    column: string;
    sortState: {
        column: string | null;
        direction: 'asc' | 'desc' | 'default';
    };
};

export const SortIcon = ({ column, sortState }: SortIconProps) => {
    if (sortState.column === column && sortState.direction !== 'default') {
        return sortState.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }

    return <FaSort />;
};
