import { Box, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { Trans, useTranslation } from 'react-i18next';

import desktopFrame from '../../assets/Frame2.png';
import { useAppConfigStore, useReportStore } from '../../stores';
import { formatDate } from '../../util/formatDate';
import defaultClasses from './ReportHeader.module.css';

export const ReportHeader = () => {
    const { t } = useTranslation();
    const { lastScanDate, nextScanDate } = useReportStore();
    const { isScreenSmall } = useAppConfigStore();

    return (
        <Box
            className={defaultClasses.root}
            display="grid"
            gridTemplateColumns={isScreenSmall ? '1fr' : '1fr 1fr'}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                px="24px"
            >
                <Text fontSize="28px" fontWeight="700">
                    {t('reportPage.results')}
                </Text>
                <Stack spacing="16px">
                    <Box>
                        <Text fontSize="15px">
                            <Trans
                                i18nKey="reportPage.description"
                                default="We have scanned your WIX site to identify integrated components that may require user consent based on the <bold>General Data Protection Regulation</bold> (GDPR) and <bold>ePrivacy Directive</bold> (ePR) and therefore will be included in your consent banner."
                                components={{ bold: <b /> }}
                            />
                        </Text>
                    </Box>
                    <Box display="flex" gap="8px">
                        <Text>
                            <b>{t('reportPage.scanDate')}: </b>
                            {formatDate(lastScanDate)}
                        </Text>
                        <Text>
                            <b>{t('reportPage.nextScan')}: </b>
                            {formatDate(nextScanDate)}
                        </Text>
                    </Box>
                </Stack>
            </Box>
            {isScreenSmall ? null : (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={desktopFrame} alt="" width="80%" />
                </Box>
            )}
        </Box>
    );
};
