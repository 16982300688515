export const deepEqual = (x: any, y: any) => {
    const compareObjects = (obj1: any, obj2: any): boolean => {
        const keysX = Object.keys(obj1);
        const keysY = Object.keys(obj2);

        if (keysX.length !== keysY.length) {
            return false;
        }

        return keysX.every(
            (key) => keysY.includes(key) && deepEqual(obj1[key], obj2[key])
        );
    };

    const compareArrays = (arr1: any[], arr2: any[]): boolean => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i += 1) {
            if (typeof arr1[i] === 'object' && arr1[i] !== null) {
                if (!deepEqual(arr1[i], arr2[i])) {
                    return false;
                }
            } else if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    };

    if (x === y) {
        return true;
    }

    if (
        typeof x === 'object' &&
        x != null &&
        typeof y === 'object' &&
        y != null
    ) {
        return compareObjects(x, y);
    }

    if (Array.isArray(x) && Array.isArray(y)) {
        return compareArrays(x, y);
    }

    return false;
};

export const deepCopy = (obj: any) => {
    if (typeof obj === 'object' && obj !== null) {
        const copy = obj.constructor();

        Object.keys(obj).forEach((attr) => {
            if (Object.hasOwn(obj, attr)) {
                copy[attr] = deepCopy(obj[attr]);
            }
        });

        return copy;
    }

    return obj;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-GB').format(date);
};

export const toMinutes = (minutes: number): number => minutes * 60000;
