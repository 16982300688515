import { Flex, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { AppError, useAuthStore } from '../../stores';

type Props = {
    appError: AppError;
};

export const AppInitializationError = ({ appError }: Props) => {
    const { appInstanceId, domain } = useAuthStore();
    const { t } = useTranslation();

    return (
        <Flex
            direction="column"
            justifyContent="center"
            align="center"
            height="100%"
            textAlign="center"
            gap={3}
        >
            <Text color="white" fontWeight={600}>
                {t('initializationError.title')}
            </Text>
            <Text color="white">Reason</Text>
            <Text color="white" fontWeight={600}>
                {t(`initializationError.reason.${appError.reason}`)}
            </Text>
            <Text color="white">
                {t('initializationError.message', {
                    appInstanceId,
                    domain,
                    date: new Date().toISOString()
                })}
            </Text>
        </Flex>
    );
};
