import { Box, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import WelcomeBannerPlain from '../../assets/WelcomeBannerPlain.svg';

export const MainBanner = () => {
    const { t } = useTranslation();

    return (
        <Box mb={10} w="100%" h="100%" position="relative">
            <Box
                position="absolute"
                right="30%"
                top="50%"
                transform="translateY(-50%)"
                ml="5%"
            >
                <Text
                    fontSize="clamp(32px, 2vw + 1rem, 52px)"
                    color="white"
                    fontWeight={600}
                    lineHeight="1.2"
                    letterSpacing={1}
                >
                    {t('dashboard.mainBanner.welcomeMsgP1')}
                </Text>
                <Text
                    fontSize="clamp(32px, 2vw + 1rem, 52px)"
                    color="#1A32CF"
                    lineHeight="1.2"
                    letterSpacing={1}
                >
                    {t('dashboard.mainBanner.welcomeMsgP2')}
                </Text>
            </Box>
            <img
                src={WelcomeBannerPlain}
                alt=""
                style={{ borderRadius: '8px' }}
            />
        </Box>
    );
};
