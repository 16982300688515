import {
    MutationOptions,
    useMutation,
    UseMutationResult
} from '@tanstack/react-query';

import { signIn } from '../authService';
import { SignInResponse } from '../generated-types';

export const useSignIn = (
    mutationOptions: MutationOptions<SignInResponse, unknown, string>
): UseMutationResult<SignInResponse, unknown, string> =>
    useMutation<SignInResponse, unknown, string, unknown>({
        mutationFn: signIn,
        ...mutationOptions
    });
