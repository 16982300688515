import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
    amplitude.init(`${import.meta.env.VITE_AMPLITUDE_API_KEY}`, {
        defaultTracking: {
            attribution: false,
            pageViews: false,
            sessions: false,
            formInteractions: false,
            fileDownloads: false
        },
        serverZone: 'EU'
    });
};
