import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getBannerComponents } from '../api/api';
import { useAuthStore } from '../stores';
import { Component } from '../types';
import { groupComponentsBy } from '../util/groupComponentsBy';

export const useComponents = () => {
    const navigate = useNavigate();

    const { appInstanceId, domain } = useAuthStore();

    const { data, isLoading } = useQuery({
        queryKey: ['bannerComponents', { appInstanceId, domainUrl: domain }],
        queryFn: () => getBannerComponents({ appInstanceId, domainUrl: domain })
    });

    const components = useMemo<Component[]>(() => {
        if (data) {
            const dataComponents =
                data.components?.map((component: any) => ({
                    ...component,
                    blockerCategory: component.category
                })) ?? [];
            const { otherServices } = data;
            let otherServicesComponents: Component[] = [];

            if (otherServices?.length) {
                otherServicesComponents = otherServices.map((url: string) => ({
                    name: url,
                    hasConflict: true,
                    type: 'otherServices'
                }));
            }

            if (dataComponents) {
                return [...dataComponents, ...otherServicesComponents];
            }

            return [];
        }

        return [];
    }, [data]);

    useEffect(() => {
        if (data && !components.length) {
            navigate('/banner/appearance');
        }
    }, [components.length, data, navigate]);

    const componentsWithEmbededScripts = useMemo(
        () =>
            // const embededScripts = [
            //     {
            //         name: 'Custom embed name provided in WIX',
            //         title: 'Title of the component',
            //         description: 'Custom embed description provided in WIX',
            //         mappedUrls: [
            //             'https://www.cookiebot.com/custom-mapping-url',
            //             'https://www.cookiebot.com/custom-mapping-url2'
            //         ],
            //         recommendedCategory: 'marketing',
            //         type: 'customEmbeds',
            //         recommendedAction: '',
            //         blockerCategory: 'unknown',
            //         blockedPriorConsent: false,
            //         hasConflict: false,
            //         badgeStatus: 'grey'
            //     }
            // ];
            [...components],
        [components]
    );

    const groupedComponents = useMemo(() => {
        if (componentsWithEmbededScripts) {
            return groupComponentsBy(componentsWithEmbededScripts);
        }

        return [];
    }, [componentsWithEmbededScripts]);

    return {
        isLoading,
        groupedComponents
    };
};
