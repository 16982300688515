import {
    LanguageTranslation,
    TextLabelsFormState
} from './types/Content.types';

export const textLabelsInitialFormState: TextLabelsFormState = {
    selectedLanguage: 'en',
    bannerIntroMessage: {
        title: 'Privacy Settings',
        bannerMessage:
            "This site uses third-party website tracking technologies to provide and continually improve our services, and to display advertisements according to users' interests. I agree and may revoke or change my consent at any time with effect for the future."
    },
    legalTextLinks: {
        legalNoticeLinkText: 'Legal Notice',
        legalNoticeUrl: '',
        privacyPolicyLinkText: 'Privacy Policy',
        privacyPolicyUrl: ''
    },
    buttonLabels: {
        buttonAccept: 'Accept All',
        buttonDeny: 'Deny All',
        buttonSave: 'Save Settings',
        buttonMore: 'More Information'
    }
};

export const languagesList = [
    { label: 'English', value: 'en' },
    { label: 'German', value: 'de' },
    { label: 'French', value: 'fr' },
    { label: 'Spanish', value: 'es' },
    { label: 'Italian', value: 'it' },
    { label: 'Polish', value: 'pl' },
    { label: 'Dutch', value: 'nl' },
    { label: 'Danish', value: 'da' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Finnish', value: 'fi' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Czech', value: 'cs' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Portuguese', value: 'pt' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Chinese', value: 'zh' },
    { label: 'Russian', value: 'ru' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Greek', value: 'el' },
    { label: 'Estonian', value: 'et' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Korean', value: 'ko' },
    { label: 'Thai', value: 'th' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Belarusian', value: 'be' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Chinese (Traditional)', value: 'zh_tw' },
    { label: 'Chinese (Hong Kong)', value: 'zh_hk' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Serbian (Latin)', value: 'sr_latn' },
    { label: 'Norwegian Bokmål', value: 'nb' },
    { label: 'Norwegian Nynorsk', value: 'nn' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Portuguese (Brazil)', value: 'pt_br' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Georgian', value: 'ka' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Persian', value: 'fa' },
    { label: 'Welsh', value: 'cy' },
    { label: 'Uzbek (Latin)', value: 'uz_latn' },
    { label: 'Azerbaijani (Latin)', value: 'az_latn' },
    { label: 'Afrikaans', value: 'af' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Galician', value: 'gl' },
    { label: 'Malay', value: 'ms' }
];

export const mapResponseToTextLabels = (
    translationResponse: any,
    legalNoticeResponse: any,
    selectedLanguage: string
) => {
    const textLabels: TextLabelsFormState = {
        selectedLanguage,
        bannerIntroMessage: {
            title: translationResponse.labels.firstLayerTitle,
            bannerMessage: translationResponse.bannerMessage
        },
        legalTextLinks: {
            legalNoticeLinkText: legalNoticeResponse.links.imprint.label,
            legalNoticeUrl: legalNoticeResponse.links.imprint.url,
            privacyPolicyLinkText:
                legalNoticeResponse.links.privacyPolicy.label,
            privacyPolicyUrl: legalNoticeResponse.links.privacyPolicy.url
        },
        buttonLabels: {
            buttonAccept: translationResponse.labels.btnAcceptAll,
            buttonDeny: translationResponse.labels.btnDeny,
            buttonSave: translationResponse.labels.btnSave,
            buttonMore: translationResponse.labels.btnMore
        }
    };

    return textLabels;
};

export const mapTextLabelsToRequest = (
    formState: TextLabelsFormState
): LanguageTranslation[] => {
    const languageTranslations: LanguageTranslation[] = [
        {
            parentField: 'labels',
            childField: 'firstLayerTitle',
            translation: {
                [formState.selectedLanguage]: formState.bannerIntroMessage.title
            }
        },
        {
            parentField: '',
            childField: 'bannerMessage',
            translation: {
                [formState.selectedLanguage]:
                    formState.bannerIntroMessage.bannerMessage
            }
        },
        {
            parentField: 'labels',
            childField: 'btnAcceptAll',
            translation: {
                [formState.selectedLanguage]:
                    formState.buttonLabels.buttonAccept
            }
        },
        {
            parentField: 'labels',
            childField: 'btnDeny',
            translation: {
                [formState.selectedLanguage]: formState.buttonLabels.buttonDeny
            }
        },
        {
            parentField: 'labels',
            childField: 'btnSave',
            translation: {
                [formState.selectedLanguage]: formState.buttonLabels.buttonSave
            }
        },
        {
            parentField: 'labels',
            childField: 'btnMore',
            translation: {
                [formState.selectedLanguage]: formState.buttonLabels.buttonMore
            }
        },
        {
            parentField: 'labels',
            childField: 'privacyPolicyLinkText',
            translation: {
                [formState.selectedLanguage]:
                    formState.legalTextLinks.privacyPolicyLinkText
            }
        },
        {
            parentField: '',
            childField: 'privacyPolicyUrl',
            translation: {
                [formState.selectedLanguage]:
                    formState.legalTextLinks.privacyPolicyUrl
            }
        },
        {
            parentField: 'labels',
            childField: 'imprintLinkText',
            translation: {
                [formState.selectedLanguage]:
                    formState.legalTextLinks.legalNoticeLinkText
            }
        },
        {
            parentField: '',
            childField: 'imprintUrl',
            translation: {
                [formState.selectedLanguage]:
                    formState.legalTextLinks.legalNoticeUrl
            }
        }
    ];

    return languageTranslations;
};
