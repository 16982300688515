import { BannerScriptSwitch } from '@components/BannerScriptSwitch';
import { Box } from '@usercentrics/cookiebot-ui-react';
import { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

export const NavigationWrapper = ({ children }: Props) => (
    <Box as="aside" w="clamp(250px, 20vw, 300px)" flexShrink={0}>
        <Box mb={4} w="clamp(250px, 20vw, 300px)">
            <BannerScriptSwitch />
        </Box>
        {children}
    </Box>
);
