import { proxy, useSnapshot } from 'valtio';

type State = {
    appInstanceId: string;
    domain: string | null;
    appId: string;
    siteId: string;
    isLoading: boolean;
};

const defaultState: State = {
    appInstanceId: '',
    domain: '',
    appId: '',
    siteId: '',
    isLoading: true
};

const state = proxy(defaultState);

export const authStoreActions = {
    setAppInstanceId: (appInstanceId: string) => {
        state.appInstanceId = appInstanceId;
    },
    setDomain: (domain: string) => {
        state.domain = domain;
    },
    setAppId: (appId: string) => {
        state.appId = appId;
    },
    setSiteId: (siteId: string) => {
        state.siteId = siteId;
    },
    setIsLoading: (isLoading: boolean) => {
        state.isLoading = isLoading;
    },
    getAppInstanceId: () => state.appInstanceId
};

export const useAuthStore = () => useSnapshot<State>(state);
