import { Box, Switch, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

type Props = {
    onChange: (event: any) => void;
    isChecked: boolean | null;
};

export const ConsentBannerBackgroundShadow = ({
    isChecked,
    onChange
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'appearancePage.consentBanner.advancedSettings'
    });

    return (
        <Box
            backgroundColor="#ffffff"
            px="24px"
            py="16px"
            width="50%"
            borderRadius="6px"
        >
            <Box display="flex" justifyContent="space-between">
                <Text
                    fontSize={15}
                    fontWeight={600}
                    display="flex"
                    gap={2}
                    alignItems="center"
                >
                    {t('bannerShadowEffect')}
                </Text>
                <Box>
                    <Switch
                        size="md"
                        isChecked={!!isChecked}
                        onChange={onChange}
                    />
                </Box>
            </Box>
        </Box>
    );
};
