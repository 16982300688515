import { useEffect } from 'react';

import {
    appConfigStoreActions,
    useAppConfigStore,
    useAuthStore,
    useClientSettingsStore,
    usePremiumStore,
    useReportStore
} from '../stores';

export const useAppConfig = () => {
    const { isPremium, isPremiumLoading } = usePremiumStore();
    const { clientSettingsId, clientSettingsLoading } =
        useClientSettingsStore();
    const { reportData } = useReportStore();
    const { isLoading: isAuthLoading } = useAuthStore();
    const { isLoading: isReportLoading } = useReportStore();
    const { appError } = useAppConfigStore();

    useEffect(() => {
        appConfigStoreActions.setAppLoading(
            !appError &&
                (isAuthLoading ||
                    isReportLoading ||
                    isPremiumLoading ||
                    !clientSettingsId ||
                    clientSettingsLoading)
        );
    }, [
        clientSettingsId,
        clientSettingsLoading,
        appError,
        isAuthLoading,
        isPremium,
        isPremiumLoading,
        isReportLoading,
        reportData
    ]);
};
